import { Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import { FormSeleksiVendorAsetContent } from './__SeleksiVendorAsetComps__';
import { useQuery } from 'react-query';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import SeleksiVendorAsetApi from './__SeleksiVendorAsetApi__';
import { mappingDetailData } from './__SeleksiVendorAsetUtils__';
import { Alert, DataStatus } from 'components';

export const SeleksivendorAsetCreate = ({ setNavbarTitle }) => {
  const { id } = useParams();

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    text: '',
    variant: 'primary',
  });

  const {
    data: detailPermintaanAset,
    isFetching: loadingPermintaanAset,
    refetch,
  } = useQuery(
    ['permintaanAsetSeleksiVendorAset', 'single', id],
    () =>
      SeleksiVendorAsetApi.getSinglePermintaanAset({
        id_permintaan_aset: id,
      }),
    { enabled: !!id }
  );

  useEffect(() => {
    setNavbarTitle('Permintaan Aset');
    return () => {};
  }, []);

  return (
    <>
      <Alert
        show={alertConfig?.show}
        text={alertConfig?.text}
        variant={alertConfig?.variant}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
        showCloseButton
      />
      {loadingPermintaanAset ? (
        <DataStatus loading text="Memuat Data..." />
      ) : (
        <Formik
          initialValues={mappingDetailData(detailPermintaanAset?.data ?? {})}
        >
          <FormSeleksiVendorAsetContent
            action="create"
            setAlertConfig={setAlertConfig}
            refetch={refetch}
          />
        </Formik>
      )}
    </>
  );
};
