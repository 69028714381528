import Services from '../../../services';

class SeleksiVendorApi {
  get(params) {
    return Services.get('/seleksi_vendor/page', { params });
  }

  getVendorDropdon() {
    return Services.get('/seleksi_vendor/vendor');
  }

  getSingle(params) {
    return Services.get('/seleksi_vendor/single', { params });
  }

  getPurchaseRequest(params) {
    return Services.get('/seleksi_vendor/purchase_request', { params });
  }

  getNomor(params) {
    return Services.get('/seleksi_vendor/no_baru', { params });
  }

  createOrUpdate(data) {
    return Services.post('/seleksi_vendor', data);
  }

  delete(data) {
    return Services.post('/seleksi_vendor/delete', data);
  }

  upload(data) {
    return Services.post('/upload/seleksi_vendor_multiple', data);
  }
}

export default new SeleksiVendorApi();
