import Services from "services";

class TransferProduksiSubkonApi {
  // transfer
  jobmix_transfer_subkon(value) {
    return Services.get("/jobmix_transfer_subkon", { params: {...value} });
  }
  page(value) {
    return Services.get("/jobmix_transfer_subkon/page", { params: {...value} });
  }

  // permintaan
  permintaan_page(value) {
    return Services.get("/jobmix_transfer_subkon/page_permintaan", { params: {...value} });
  }
  single_permintaan(value) {
    return Services.get("/jobmix_transfer_subkon/single_permintaan", { params: {...value} });
  }
  create(value) {
    return Services.post("/jobmix_transfer_subkon", value);
  }

  // atribut
  dropdown(value) {
    return Services.get("/jobmix_transfer_subkon/dropdown", { params: {...value} });
  }
  no_baru(value) {
    return Services.get("/jobmix_transfer_subkon/no_baru", { params: {...value} });
  }
}

export default new TransferProduksiSubkonApi();
