import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Row, Col } from "react-bootstrap";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  BackButton,
  ActionButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
} from "../../../../components";
import { TableNumber, DateConvert } from "../../../../utilities";
import { SeleksiVendorApi } from "../../../../api";

const ListPurchaseRequestSeleksiVendor = ({ setNavbarTitle }) => {
  const history = useHistory();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataPurchaseRequest, setDataPurchaseRequest] = useState([]);
  const [processedData, setProcessedData] = useState({});
  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
    key: "",
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "detail",
  });

  const getInitialData = () => {
    setIsPageLoading(true);
    setAlertConfig({
      ...alertConfig,
      show: false,
    });

    SeleksiVendorApi.getPurchaseRequest({
      q: paginationConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then(({ data }) => {
        setDataPurchaseRequest(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      )
      .finally(() => {
        setIsPageLoading(false);
        if (paginationConfig.key != "") {
          setAlertConfig({
            show: true,
            variant: "primary",
            text: `Hasil dari pencarian: ${paginationConfig.key}`,
          });
        }
      });
  };

  const onSubmitSearchHandler = (e) => {
    e.preventDefault();
    setSearchConfig({
      ...searchConfig,
      status: true,
    });
    getInitialData();
  };

  const onResetHandler = () => {
    setPaginationConfig({
      ...paginationConfig,
      page: 1,
      dataLength: 10,
    });
    setSearchConfig({
      show: false,
      key: "",
    });
    setAlertConfig({
      ...alertConfig,
      show: false,
    });
  };

  const decimalConvert = (value) => {
    const convertToNumber = parseFloat(value);
    const checkConvert =
      convertToNumber === NaN ? 0 : parseFloat(String(convertToNumber));

    return new Intl.NumberFormat("id-ID").format(checkConvert);
  };

  useEffect(() => {
    setNavbarTitle("Purchase Request Yang Belum Dibuatkan Seleksi Vendor");
    getInitialData();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    searchConfig.status,
    paginationConfig.page,
    paginationConfig.dataLength,
    paginationConfig.key,
  ]);

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <Th>Tgl. Purchase Request</Th>
            <Th>No. Purchase Request</Th>
            <Th>Keperluan</Th>
            <Th>Tgl. Pemakaian</Th>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {dataPurchaseRequest.map((val, index) => (
            <Tr key={index}>
              <TdFixed>
                {TableNumber(
                  paginationConfig.page,
                  paginationConfig.dataLength,
                  index
                )}
              </TdFixed>
              <Td>
                {val.tgl_purchase_request
                  ? DateConvert(new Date(val.tgl_purchase_request)).defaultDMY
                  : "-"}
              </Td>
              <Td>{val.no_purchase_request}</Td>
              <Td>{val.keperluan}</Td>
              <Td>
                {val.tgl_pemakaian
                  ? DateConvert(new Date(val.tgl_pemakaian)).defaultDMY
                  : "-"}
              </Td>
              <Td className="d-flex justify-content-center">
                <ActionButton
                  size="sm"
                  className="m-1 text-nowrap"
                  text="Lihat Detail"
                  onClick={() => {
                    setProcessedData(val);
                    setModalConfig({
                      show: true,
                      type: "detail",
                    });
                  }}
                />
                <ActionButton
                  size="sm"
                  className="m-1 text-nowrap"
                  text="Seleksi Vendor"
                  onClick={() =>
                    history.push(
                      "/transaksi/seleksi-vendor/tambah/" +
                        val.id_purchase_request
                    )
                  }
                />
              </Td>
            </Tr>
          ))}
        </TBody>
      </Table>
    );

    console.log(paginationConfig);
    return (
      <>
        <DataTable />

        <Pagination
          dataLength={paginationConfig.dataLength}
          dataNumber={
            paginationConfig.page * paginationConfig.dataLength -
            paginationConfig.dataLength +
            1
          }
          dataPage={
            paginationConfig.dataCount < paginationConfig.dataLength
              ? paginationConfig.dataCount
              : paginationConfig.page * paginationConfig.dataLength
          }
          dataCount={paginationConfig.dataCount}
          currentPage={paginationConfig.page}
          totalPage={paginationConfig.totalPage}
          onPaginationChange={({ selected }) =>
            setPaginationConfig({
              ...paginationConfig,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPaginationConfig({
              ...paginationConfig,
              page: 1,
              dataLength: e.target.value,
            })
          }
        />
      </>
    );
  };

  const PageModal = () => {
    const [modalData, setModalData] = useState({});
    const [isModalLoading, setIsModalLoading] = useState(true);
    const [isModalFetchingFailed, setIsModalFetchingFailed] = useState(false);

    const getInitialModalData = () => {
      SeleksiVendorApi.getSingle({
        id_purchase_request: processedData.id_purchase_request,
      })
        .then(({ data }) => {
          setModalData(data.data);
          setIsModalFetchingFailed(false);
        })
        .catch(() => {
          setIsModalFetchingFailed(true);
        })
        .finally(() => setIsModalLoading(false));
    };

    useEffect(() => {
      getInitialModalData();

      return () => {
        setIsModalLoading(true);
        setIsModalFetchingFailed(false);
      };
    }, []);

    const ModalTitle = () =>
      modalConfig.type.toLowerCase() === "detail" ? (
        <span className="text-primary">Detail Seleksi Vendor</span>
      ) : modalConfig.type.toLowerCase() === "update" ? (
        <span className="text-success">Ubah Seleksi Vendor</span>
      ) : modalConfig.type.toLowerCase() === "delete" ? (
        <span className="text-danger">Hapus Seleksi Vendor</span>
      ) : (
        <span className="text-primary">Tambah Seleksi Vendor</span>
      );

    const ModalDetail = () => {
      const ModalDetailInfo = () => {
        const ModalInfoItem = ({ title, value }) => (
          <tr>
            <td>
              <small>{title}</small>
            </td>
            <td className="pl-3 pr-1">
              <small>:</small>
            </td>
            <td>
              <small className="text-capitalize">{value}</small>
            </td>
          </tr>
        );

        return (
          <Row className="mb-3">
            <Col>
              <table>
                <tbody>
                  <ModalInfoItem
                    title="Tgl. Purchase Request"
                    value={
                      modalData.tgl_purchase_request
                        ? DateConvert(new Date(modalData.tgl_purchase_request))
                            .detail
                        : "-"
                    }
                  />
                  <ModalInfoItem
                    title="Tgl. Pemakaian"
                    value={
                      modalData.tgl_pemakaian
                        ? DateConvert(new Date(modalData.tgl_pemakaian)).detail
                        : "-"
                    }
                  />
                </tbody>
              </table>
            </Col>
            <Col>
              <table>
                <tbody>
                  <ModalInfoItem
                    title="No. Purchase Request"
                    value={
                      modalData.no_purchase_request
                        ? modalData.no_purchase_request
                        : "-"
                    }
                  />
                  <ModalInfoItem
                    title="Keperluan"
                    value={modalData.keperluan ? modalData.keperluan : "-"}
                  />
                  <ModalInfoItem
                    title="Proyek"
                    value={modalData.nama_proyek ? modalData.nama_proyek : "-"}
                  />
                </tbody>
              </table>
            </Col>
          </Row>
        );
      };

      const ModalDetailTable = () => {
        return (
          <Table>
            <THead>
              <Tr>
                <ThFixed>No.</ThFixed>
                <ThFixed>Kode Item</ThFixed>
                <Th>Nama Item</Th>
                <Th>Satuan</Th>
                <Th>Qty</Th>
              </Tr>
            </THead>
            <TBody>
              {modalData.pr_detail.map((val, index) => (
                <Tr key={index}>
                  <Td>{index + 1}</Td>
                  <TdFixed>{val.kode_item}</TdFixed>
                  <Td> {val.nama_item} </Td>
                  <Td>{val.satuan_beli}</Td>
                  <Td textRight>{decimalConvert(parseFloat(val.qty_pr))}</Td>
                </Tr>
              ))}
            </TBody>
          </Table>
        );
      };

      return (
        <Modal.Body>
          {isModalLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : isModalFetchingFailed ? (
            <DataStatus text="Data gagal dimuat" />
          ) : (
            <>
              <ModalDetailInfo />
              {modalData.pr_detail ? (
                modalData.pr_detail.length > 0 ? (
                  <>
                    <ModalDetailTable />
                    <div>
                      <strong>Catatan : </strong>
                      <br></br>
                      {modalData.catatan_purchase_request ?? "-"}
                    </div>
                  </>
                ) : (
                  <DataStatus text="Tidak ada item" />
                )
              ) : (
                <DataStatus text="Tidak ada item" />
              )}
            </>
          )}
        </Modal.Body>
      );
    };

    return (
      <Modal
        size="xl"
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton>
          <ModalTitle />
        </Modal.Header>
        {modalConfig.type.toLowerCase() === "detail" && <ModalDetail />}
      </Modal>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row>
            <Col md="8">
              <InputSearch
                onChange={(e) => {
                  setTimeout(() => {
                    setPaginationConfig({
                      ...paginationConfig,
                      key: e.target.value,
                      page: "1",
                    });
                  }, 1000);
                }}
                onSubmit={(e) => e.preventDefault()}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton
            onClick={() => history.push("/transaksi/seleksi-vendor")}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : dataPurchaseRequest ? (
        dataPurchaseRequest.length > 0 ? (
          <PageContent />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Data gagal dimuat" />
      )}
      {modalConfig.show && <PageModal />}
    </CRUDLayout>
  );
};

export default ListPurchaseRequestSeleksiVendor;
