import Services from 'services';

class TransferSubkonWorkOrderApi {
  page(params) {
    return Services.get('/transfer_work_order_subkon/page', { params });
  }

  preformPage(params) {
    return Services.get('/transfer_work_order_subkon/work_order', { params });
  }

  singlePermintaanPreform(params) {
    return Services.get('/transfer_work_order_subkon/detail_work_order', {
      params,
    });
  }

  singlePermintaan(params) {
    return Services.get('/transfer_work_order_subkon/permintaan', { params });
  }

  no_baru(params) {
    return Services.get('/transfer_work_order_subkon/no_baru', { params });
  }

  getDropdownSubkon(params) {
    return Services.get('/dropdown/item_subkon', { params });
  }

  getDropdownAset(params) {
    return Services.get('/dropdown/item_aset', { params });
  }

  getDropdownVendor(params) {
    return Services.get('/dropdown/vendor', { params });
  }

  save(data) {
    return Services.post('/transfer_work_order_subkon', data);
  }
}

export default new TransferSubkonWorkOrderApi();
