import {
  ButtonCreate,
  ButtonDelete,
  ButtonDetail,
  ButtonUpdate,
} from '@bhawanadevteam/react-core';
import {
  ActionButton,
  DataStatus,
  Input,
  SelectSearch,
  TBody,
  Table,
  Td,
  Th,
  ThFixed,
  Tr,
} from 'components';
import Thead from 'components/Table/THead';
import { Formik, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import * as Yup from 'yup';

export const TablePermintaanAset = ({
  dataTable,
  type,
  action,
  setModalConfig,
  modalConfig,
}) => {
  return (
    <div>
      <Table>
        <Thead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <Th>Grup Aset</Th>
            <Th>Kategori Aset</Th>
            <Th>Jenis Aset</Th>
            <Th>Nama Item Aset</Th>
            <Th>Keperluan</Th>
            <Th>Status Seleksi</Th>
            {action !== 'detail' && <ThFixed>Aksi</ThFixed>}
          </Tr>
        </Thead>
        <TBody>
          {dataTable.length > 0 ? (
            dataTable?.map((val, ind) => (
              <Tr key={ind}>
                <Td>{ind + 1}</Td>
                <Td>{val.namaGrupAset ?? '-'}</Td>
                <Td>{val.namaKategoriAset ?? '-'}</Td>
                <Td>{val.namaJenisAset ?? '-'}</Td>
                <Td>{val.namaAset ?? '-'}</Td>
                <Td className="text-capitalize">{val.keperluanLabel ?? '-'}</Td>
                <Td
                  className={`${
                    val?.isSeleksi ? 'text-success' : 'text-danger'
                  } text-center`}
                >
                  {val.isSeleksi ? 'SUDAH TERSELEKSI' : 'BELUM TERSELEKSI'}
                </Td>
                {action !== 'detail' && (
                  <Td>
                    <ButtonGroup>
                      <ButtonCreate
                        noText
                        icon
                        size="sm"
                        onClick={() =>
                          setModalConfig({
                            ...modalConfig,
                            show: true,
                            type: 'add',
                            data: val,
                          })
                        }
                      />
                    </ButtonGroup>
                  </Td>
                )}
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan="8">
                <DataStatus text="Tidak Ada Data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
    </div>
  );
};
