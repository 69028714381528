import { DataStatus } from 'components';
import React, { useState } from 'react';
import { Card, Nav, Tab } from 'react-bootstrap';
import DashboardApi from '../__DashboardApi__';
import { rupiahConvert } from 'utilities2';
import { useQuery } from 'react-query';
import { ChartBarVertical } from 'components2';

const rupiah = rupiahConvert();

export const TabTotalPembelianPerHari = ({
  monthOptions,
  startMonth,
  endMonth,
  year,
}) => {
  const [selectedMonth, setSelectedMonth] = useState({
    label: '',
    value: startMonth,
  });

  const monthsInRange = monthOptions.filter(
    (val) => val.value >= startMonth && val.value <= endMonth
  );

  const getTotalPembelianHari = useQuery(
    [
      'dashboard',
      'total-pembelian-per-hari',
      startMonth,
      endMonth,
      year,
      selectedMonth,
    ],
    () =>
      DashboardApi.getTotalPembelianHari({
        tahun: year,
        bulan: selectedMonth?.value,
      })
  );

  return (
    <Card className="border mt-3">
      {monthsInRange?.length ? (
        <Tab.Container
          key={monthsInRange.join('-')}
          defaultActiveKey={monthsInRange[0].value}
        >
          <Card.Header className="bg-white">
            <Nav variant="tabs" className="bg-white">
              {monthsInRange?.map((month) => (
                <Nav.Item className="bg-white">
                  <Nav.Link
                    className="py-1 px-2"
                    eventKey={month.value}
                    onClick={() =>
                      setSelectedMonth({
                        label: month.label,
                        value: month.value,
                      })
                    }
                  >
                    {month.label}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Card.Header>

          <Card.Body>
            <Tab.Content>
              <div className="text-center">
                <b>Total Pembelian Per Hari Bulan {selectedMonth?.label}</b>
              </div>
              {getTotalPembelianHari.isFetching ? (
                <div className="d-flex align-items-center justify-content-center my-5">
                  Memuat data . . .
                </div>
              ) : (
                <>
                  <ChartBarVertical
                    showLegend={false}
                    data={{
                      labels: getTotalPembelianHari.data?.data?.map(
                        (val) => val?.harian ?? '-'
                      ),
                      datasets: [
                        {
                          data: getTotalPembelianHari.data?.data?.map(
                            (val) => val?.total ?? 0
                          ),
                        },
                      ],
                    }}
                    options={{
                      plugins: {
                        tooltip: {
                          callbacks: {
                            label: (val) => rupiah.getWithComa(val.raw),
                          },
                        },
                      },
                      scales: {
                        y: {
                          ticks: {
                            callback: (val) => rupiah.getWithComa(val),
                          },
                        },
                      },
                    }}
                  />
                </>
              )}
            </Tab.Content>
          </Card.Body>
        </Tab.Container>
      ) : (
        <DataStatus text="Tidak ada data" />
      )}
    </Card>
  );
};
