import Services from '../../services'

class VendorApi {
  get() {
    return Services.get('/vendor_kualifikasi')
  }
  
  getPage(page, dataLength, key) {
    return Services.get(`/vendor_kualifikasi/page/?page=${page}&per_page=${dataLength}&q=${key}`)
  }

  single(id) {
    return Services.get('/vendor_kualifikasi/single?id_vendor_prakualifikasi='+ id)
  }

  getDokumenUtama(data) {
    return Services.get('/vendor_kualifikasi/dokumen_utama?id_vendor_prakualifikasi='+ data)
  }

  getDokumenLainnya(data) {
    return Services.get('/vendor_kualifikasi/dokumen_lainnya?id_vendor_prakualifikasi='+ data)
  }

  getJumlahItem(data) {
    return Services.get('/vendor_kualifikasi/jumlah_item?id_vendor_prakualifikasi='+ data)
  }
  
  update(value) {
    return Services.put('/vendor_kualifikasi', value)
  }
}

export default new VendorApi()