import Services from "services";

class RegItemUpahApi {
  getPage(page, dataLength, key) {
    return Services.get(`/upah/page/?page=${page}&per_page=${dataLength}&q=${key}`);
  }
  getSatuan() {
    return Services.get("/upah/dropdown?tipe=satuan");
  }
  getKelompok() {
    return Services.get("/upah/kelompok");
  }
  getPabrikan() {
    return Services.get("/upah/dropdown?tipe=pabrikan");
  }
  generateKodeItem() {
    return Services.get("/upah/no_baru");
  }
  create(data) {
    return Services.post("/upah", data);
  }
  update(data) {
    return Services.put("/upah", data);
  }
  delete(id_upah) {
    return Services.post("/upah/delete", id_upah);
  }
  search(key) {
    return Services.get(`upah/page/?q=${key}`);
  }
  show(id_upah) {
    return Services.put("/upah/show", id_upah);
  }
  hide(id_upah) {
    return Services.put("/upah/hide", id_upah);
  }
  // single() {
  //   return Services.get("/upah/");
  // }
}

export default new RegItemUpahApi();
