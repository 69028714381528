import React, { useState, useEffect } from 'react';
import { Row, Col, Card, ButtonGroup } from 'react-bootstrap';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import {
  Alert,
  BackButton,
  ActionButton,
  DataStatus,
  Input,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  DatePicker,
  TextArea,
  SelectSearch,
} from '../../../../components';
import { DateConvert, RupiahConvert } from '../../../../utilities';
import { PurchaseOrderApi } from '../../../../api';
import { IoAddOutline, IoEyeOutline, IoTrashOutline } from 'react-icons/io5';

import { FiRefreshCcw } from 'react-icons/fi';
import ReactNumberFormat from 'components/Input/ReactNumberFormat';
import Axios from 'axios';
import { ModalSeleksiVendor } from '../Section';

const UbahPurchaseOrder = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();
  const routerState = location.state;
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [dataPurchaseOrder, setDataPurchaseOrder] = useState({});
  const [dataPODetail, setDataPODetail] = useState([]);
  const [dataSVDetail, setDataSVDetail] = useState([]);
  const [dataVendor, setDataVendor] = useState([]);
  const [dropdownVendor, setDropdownVendor] = useState([]);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    PurchaseOrderApi.getSingle({ id_purchase_order: id })
      .then(({ data }) => {
        const dataPO = data.data;
        const dataDetail = [];

        setDataPurchaseOrder(dataPO);

        dataPO.po_detail &&
          dataPO.po_detail.map((val) => {
            const processed = {
              id_item_buaso: val.id_item_buaso,
              qty_order: val.qty_order,
              harga_kesepakatan: val.harga_kesepakatan,
              kode_item: val.kode_item,
              nama_item: val.nama_item,
              id_seleksi_vendor_detail: val.id_seleksi_vendor_detail[0],
              nama_satuan: val.nama_satuan,
              nama_proyek: val.nama_proyek,
              nama_vendor_angkut: val.nama_vendor_angkut,
              harga_kesepakatan_biaya_angkut:
                val.harga_kesepakatan_biaya_angkut,
              id_vendor_angkut: val.id_vendor_angkut,
            };
            dataDetail.push(processed);
          });

        setDataPODetail(dataDetail);

        PurchaseOrderApi.getSingleVendor({ id_vendor: data.data.id_vendor })
          .then(({ data }) => {
            const dataSV = data.data;
            const dataDetail = [];

            setDataVendor(dataSV);

            dataSV?.seleksi_vendor &&
              dataSV?.seleksi_vendor.map((val) => {
                const processed = {
                  id_item_buaso: val.id_item_buaso,
                  qty_order: val.qty_order,
                  harga_kesepakatan: val.harga_kesepakatan,
                  kode_item: val.kode_item,
                  nama_item: val.nama_item,
                  id_seleksi_vendor_detail: val.id_seleksi_vendor_detail,
                  satuan_beli: val.satuan_beli,
                  no_purchase_request: val.no_purchase_request,
                  tgl_seleksi_vendor: val.tgl_seleksi_vendor,
                  nama_proyek: val.nama_proyek,
                  nama_vendor_angkut: val.nama_vendor_angkut,
                  harga_kesepakatan_biaya_angkut:
                    val.harga_kesepakatan_biaya_angkut,
                };

                dataDetail.push(processed);
              });

            setDataSVDetail(dataDetail);
          })
          .catch(() => {
            setIsFetchingFailed(true);
            setAlertConfig({
              show: true,
              variant: 'danger',
              text: 'Data gagal dimuat!',
            });
          })
          .finally(() => setIsPageLoading(false));
      })
      .catch(() => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const getDropdown = () => {
    Axios.all([
      PurchaseOrderApi.getDropdownVendor({ status_vendor: '1' }),
    ]).then(
      Axios.spread((dataVendor) => {
        setDropdownVendor(dataVendor.data.data);
      })
    );
  };

  useEffect(() => {
    setNavbarTitle('Ubah Purchase Order');
    getInitialData();
    getDropdown();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  const PageContent = () => {
    const [dataListItemPurchaseOrder, setDataListItemPurchaseOrder] = useState(
      []
    );
    const [dataForm, setDataForm] = useState({
      tgl_purchase_order: dataPurchaseOrder.tgl_purchase_order,
      no_purchase_order: dataPurchaseOrder.no_purchase_order,
    });
    const dataPengaju = dataPurchaseOrder?.stakeholder
      ? dataPurchaseOrder.stakeholder
      : [];

    const formInitialValues = {
      id_vendor: dataPurchaseOrder.id_vendor,
      id_purchase_order: dataPurchaseOrder.id_purchase_order,
      // id_purchase_request: dataPurchaseOrder.id_purchase_request,
      tgl_purchase_order: dataForm.tgl_purchase_order,
      no_purchase_order: dataForm.no_purchase_order,
      tgl_pengiriman: dataPurchaseOrder.tgl_pengiriman,
      alamat_tujuan_pengiriman: dataPurchaseOrder.alamat_tujuan_pengiriman
        ? dataPurchaseOrder.alamat_tujuan_pengiriman
        : '',
      keterangan: dataPurchaseOrder.keterangan
        ? dataPurchaseOrder.keterangan
        : '',
      diskon: dataPurchaseOrder.diskon ? dataPurchaseOrder.diskon : 0,
      ppn: dataPurchaseOrder.ppn ? dataPurchaseOrder.ppn : 0,
      dp: dataPurchaseOrder.dp ? dataPurchaseOrder.dp : 0,
      pembayaran: dataPurchaseOrder?.pembayaran ?? '',
      detail: dataListItemPurchaseOrder ?? [],
    };

    const formValidationSchema = Yup.object().shape({
      tgl_purchase_order: Yup.string().required('Pilih tanggal purchase order'),
      no_purchase_order: Yup.string().required(
        'Pilih tanggal untuk menentukan nomor'
      ),
      tgl_pengiriman: Yup.string().required('Pilih tanggal pengiriman'),
      alamat_tujuan_pengiriman: Yup.string().required(
        'Masukan alamat tujuan pengiriman'
      ),
      diskon: Yup.string().required(),
      ppn: Yup.string().required(),
      pembayaran: Yup.string().required('Masukan Pembayaran'),
    });

    const formSubmitHandler = (values) => {
      const detailData = dataListItemPurchaseOrder.map((val) => {
        return {
          id_item_buaso: val.id_item_buaso,
          qty_order: val.qty_order,
          harga_kesepakatan: val.harga_kesepakatan,
          id_seleksi_vendor_detail: [val.id_seleksi_vendor_detail],
        };
      });

      const finalValues = {
        ...values,
        detail: values?.detail?.map((val) => ({
          id_item_buaso: val.id_item_buaso,
          qty_order: val.qty_order,
          harga_kesepakatan: val.harga_kesepakatan,
          id_seleksi_vendor_detail: [val.id_seleksi_vendor_detail],
          harga_kesepakatan_biaya_angkut: val.harga_kesepakatan_biaya_angkut,
          id_vendor_angkut: val.id_vendor_angkut,
        })),
      };

      PurchaseOrderApi.createOrUpdate(finalValues)
        .then(() => {
          setAlertConfig({
            show: true,
            variant: 'primary',
            text: 'Data berhasil diubah!',
          });
        })
        .catch(() => {
          setAlertConfig({
            show: true,
            variant: 'danger',
            text: 'Data gagal diubah!',
          });
        })
        .finally(() => getInitialData());
    };

    const decimalConvert = (value) => {
      const convertToNumber = parseFloat(value);
      const checkConvert =
        convertToNumber === NaN ? 0 : parseFloat(String(convertToNumber));

      return new Intl.NumberFormat('id-ID').format(checkConvert);
    };

    useEffect(() => {
      setDataListItemPurchaseOrder(dataPODetail);

      return () => {};
    }, []);

    const InfoSection = () => {
      const InfoItem = ({ title, value }) => (
        <tr>
          <td>
            <small>{title}</small>
          </td>
          <td>
            <small className="pl-4 pr-2">:</small>
          </td>
          <td>
            <small>{value}</small>
          </td>
        </tr>
      );

      return (
        <>
          <table>
            <tbody>
              <InfoItem
                title="Kode Vendor"
                value={dataVendor.kode_vendor ? dataVendor.kode_vendor : '-'}
              />
              <InfoItem
                title="Vendor"
                value={dataVendor.nama_vendor ? dataVendor.nama_vendor : '-'}
              />
              <InfoItem
                title="Contact Person"
                value={
                  dataVendor.contact_person ? dataVendor.contact_person : '-'
                }
              />
              <InfoItem
                title="Alamat Vendor"
                value={
                  dataVendor.alamat_vendor ? dataVendor.alamat_vendor : '-'
                }
              />
            </tbody>
          </table>
          <hr />
        </>
      );
    };

    const FormSection = ({
      values,
      errors,
      touched,
      handleChange,
      setFieldValue,
    }) => {
      const getNoPurchaseOrder = (date) => {
        PurchaseOrderApi.getNomor({ tanggal: date })
          .then(({ data }) => {
            setFieldValue('tgl_purchase_order', date);
            setFieldValue('no_purchase_order', data.data);
          })
          .catch(() => {
            setFieldValue('tgl_purchase_order', '');
            setFieldValue('no_purchase_order', '');
            setAlertConfig({
              show: true,
              variant: 'danger',
              text: 'Nomor gagal diperoleh!',
            });
          });
      };

      return (
        <>
          <Row>
            <Col>
              <DatePicker
                label="Tgl. Purchase Order"
                className="mt-1"
                selected={
                  values.tgl_purchase_order
                    ? new Date(values.tgl_purchase_order)
                    : ''
                }
                onChange={(e) => {
                  const value = e.toISOString().slice(0, 10);
                  getNoPurchaseOrder(value);
                }}
                error={
                  errors.tgl_purchase_order &&
                  touched.tgl_purchase_order &&
                  true
                }
                errorText={errors.tgl_purchase_order}
              />
            </Col>
            <Col>
              <Input
                type="text"
                label="No. Purchase Order"
                placeholder="Pilih tanggal untuk menentukan nomor"
                value={values.no_purchase_order}
                error={
                  errors.no_purchase_order && touched.no_purchase_order && true
                }
                errorText={errors.no_purchase_order}
                readOnly
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <DatePicker
                label="Tgl. Pengiriman"
                className="mt-1"
                selected={
                  values.tgl_pengiriman ? new Date(values.tgl_pengiriman) : ''
                }
                onChange={(e) => {
                  const value = e.toISOString().slice(0, 10);
                  setFieldValue('tgl_pengiriman', value);
                }}
                error={errors.tgl_pengiriman && touched.tgl_pengiriman && true}
                errorText={errors.tgl_pengiriman}
              />
              <TextArea
                label="Alamat Tujuan Pengiriman"
                name="alamat_tujuan_pengiriman"
                placeholder="Masukan alamat tujuan pengiriman"
                value={values.alamat_tujuan_pengiriman}
                onChange={handleChange}
                error={
                  errors.alamat_tujuan_pengiriman &&
                  touched.alamat_tujuan_pengiriman &&
                  true
                }
                errorText={errors.alamat_tujuan_pengiriman}
              />
            </Col>
            <Col>
              <TextArea
                label="Keterangan"
                name="keterangan"
                placeholder="Masukan keterangan"
                value={values.keterangan}
                onChange={handleChange}
                error={errors.keterangan && touched.keterangan && true}
                errorText={errors.keterangan}
                rows={5}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextArea
                label="Pembayaran"
                name="pembayaran"
                placeholder="Masukan Jangka Waktu Pembayaran"
                value={values.pembayaran}
                onChange={handleChange}
                error={errors.pembayaran && touched.pembayaran && true}
                errorText={errors.pembayaran}
              />
            </Col>
          </Row>
          <hr />
        </>
      );
    };
    const ListItemSeleksiVendor = ({
      dataListItemPurchaseOrder,
      setDataListItemPurchaseOrder,
    }) => {
      const { values, setValues } = useFormikContext();
      const [modalDetailSelesiVendorDetail, setModalDetailSelesiVendorDetail] =
        useState({ id: '', show: false });
      return (
        <>
          {modalDetailSelesiVendorDetail?.show && (
            <ModalSeleksiVendor
              setModalConfig={setModalDetailSelesiVendorDetail}
              modalConfig={modalDetailSelesiVendorDetail}
            />
          )}
          <div className="py-2 pl-1">
            <b>List Item Seleksi Vendor</b>
          </div>
          {dataSVDetail ? (
            dataSVDetail.length > 0 ? (
              <Table>
                <THead>
                  <Tr>
                    <ThFixed>No.</ThFixed>
                    <ThFixed>Kode Item</ThFixed>
                    <Th>Nama Item</Th>
                    <Th>Qty</Th>
                    <Th>Satuan Beli</Th>
                    <Th>No. Purchase Request</Th>
                    <Th>Proyek</Th>
                    <Th>Tgl. Seleksi</Th>
                    <ThFixed>No. Seleksi Vendor</ThFixed>
                    <ThFixed>Aksi</ThFixed>
                  </Tr>
                </THead>
                <TBody>
                  {dataSVDetail?.map((val, index) => (
                    <Tr key={index}>
                      <TdFixed>{index + 1}</TdFixed>
                      <TdFixed>{val.kode_item ? val.kode_item : '-'}</TdFixed>
                      <Td>
                        <div style={{ width: '200px' }}>
                          {/* {val.dimensi === "3"
                          ? `${val.nama_item} (${val.pj} X ${val.lb} X ${val.tb})`
                          : val.dimensi === "2"
                          ? `${val.nama_item} (${val.pj} X ${val.lb})`
                          : val.dimensi === "1"
                          ? `${val.nama_item} (${val.pj})`
                          : val.dimensi === "0"
                          ? val.nama_item
                          : "-"} */}
                          {val.nama_item}
                        </div>
                      </Td>
                      <Td textRight>
                        {val.qty_order
                          ? decimalConvert(parseFloat(val.qty_order))
                          : '-'}
                      </Td>
                      <Td>{val.nama_satuan ? val.nama_satuan : '-'}</Td>
                      <Td>
                        {val.no_purchase_request
                          ? val.no_purchase_request
                          : '-'}
                      </Td>
                      <Td>{val.nama_proyek ? val.nama_proyek : '-'}</Td>
                      <Td>
                        {val.tgl_seleksi_vendor
                          ? DateConvert(new Date(val.tgl_seleksi_vendor)).detail
                          : '-'}
                      </Td>
                      <Td>{val.no_seleksi_vendor ?? '-'}</Td>
                      <Td>
                        <div className="d-flex justify-content-center">
                          <ActionButton
                            size="sm"
                            className="mr-1"
                            text={<IoEyeOutline />}
                            onClick={() => {
                              setModalDetailSelesiVendorDetail({
                                id: val.id_seleksi_vendor,
                                show: true,
                              });
                            }}
                          />
                          <ActionButton
                            size="sm"
                            text={<IoAddOutline />}
                            onClick={() =>
                              setValues({
                                ...values,
                                detail: [...values?.detail, val],
                              })
                            }
                            disable={values?.detail?.find(
                              (po) =>
                                po.id_seleksi_vendor_detail ===
                                val.id_seleksi_vendor_detail
                            )}
                          />
                        </div>
                      </Td>
                    </Tr>
                  ))}
                </TBody>
              </Table>
            ) : (
              <DataStatus text="Tidak ada data" />
            )
          ) : (
            <DataStatus text="Tidak ada data" />
          )}
        </>
      );
    };

    const ListItemPurchaseOrderSection = ({
      values,
      setValues,
      errors,
      touched,
      handleChange,
      dataListItemPurchaseOrder,
      setDataListItemPurchaseOrder,
      setFieldValue,
    }) => {
      let total = 0;
      let nilaiDiskon = 0;
      let setelahDiskon = 0;
      let nilaiPPN = 0;
      let setelahPPN = 0;
      let nilaiDP = 0;
      let totalBiayaAngkut = 0;
      let grandTotal = 0;

      const TableFooterItems = ({ text, input, value }) => (
        <Tr>
          <Td colSpan={9} className="text-right">
            <b>{text}</b>
          </Td>
          <Td textRight colSpan={2}>
            <div className="d-flex align-items-center justify-content-between text-nowrap">
              {input ? (
                <div className="d-flex justify-content-center align-items-center">
                  <div style={{ width: '70px' }}>{input}</div>
                  <div className="pb-1 ml-2">
                    <b>%</b>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              <div className="text-right">
                <b>{value}</b>
              </div>
            </div>
          </Td>
        </Tr>
      );

      return (
        <>
          <div className="py-2 pl-1">
            <b>List Item Purchase Order</b>
          </div>
          {values?.detail ? (
            values?.detail.length > 0 ? (
              <>
                <Table>
                  <THead>
                    <Tr>
                      <ThFixed>No.</ThFixed>
                      <ThFixed>Kode Item</ThFixed>
                      <Th>Nama Item</Th>
                      <Th>Proyek</Th>
                      <Th>Satuan Beli</Th>
                      <Th>Qty Order</Th>
                      <Th>Harga Kesepakatan</Th>
                      <Th className="text-nowrap" width="200">
                        Vendor Angkut
                      </Th>
                      <Th>Harga Kesepakatan Biaya Angkut</Th>
                      <Th>Jumlah</Th>
                      <ThFixed>Aksi</ThFixed>
                    </Tr>
                  </THead>
                  <TBody>
                    {values?.detail?.map((val, index) => {
                      const jumlah = parseFloat(
                        val.qty_order * val.harga_kesepakatan
                      );
                      const jumlahBiayaAngkut =
                        parseFloat(val.qty_order ?? 0) *
                        parseFloat(val.harga_kesepakatan_biaya_angkut ?? 0);

                      totalBiayaAngkut = totalBiayaAngkut + jumlahBiayaAngkut;
                      total = total + jumlah;
                      nilaiDiskon = values.diskon
                        ? parseFloat(values.diskon) === 0
                          ? 0
                          : parseFloat((values.diskon / 100) * total)
                        : 0;
                      setelahDiskon = parseFloat(total - nilaiDiskon);
                      nilaiPPN = values.ppn
                        ? parseFloat(values.ppn) === 0
                          ? 0
                          : parseFloat((values.ppn / 100) * setelahDiskon)
                        : 0;
                      setelahPPN = parseFloat(setelahDiskon + nilaiPPN);

                      grandTotal = setelahPPN + totalBiayaAngkut;
                      nilaiDP = values.dp
                        ? parseFloat(values.dp) === 0
                          ? 0
                          : parseFloat((values.dp / 100) * grandTotal)
                        : 0;

                      return (
                        <Tr key={index}>
                          <TdFixed>{index + 1}</TdFixed>
                          <TdFixed>{val.kode_item}</TdFixed>
                          <Td>
                            <div style={{ width: '200px' }}>
                              {val.nama_item}
                            </div>
                          </Td>
                          <Td>{val.nama_proyek}</Td>
                          <Td>{val.nama_satuan}</Td>
                          <Td textRight>
                            {decimalConvert(parseFloat(val.qty_order))}
                          </Td>
                          <Td textRight>
                            {
                              RupiahConvert(
                                parseFloat(val.harga_kesepakatan).toString()
                              ).getWithComa
                            }
                          </Td>
                          <Td textRight>
                            <SelectSearch
                              key={val.id_vendor_angkut}
                              name="id_vendor_angkut"
                              onChange={(item) => {
                                const dataVendorAngkut = values?.detail?.map(
                                  (res, ind) => ({
                                    ...res,
                                    id_vendor_angkut:
                                      index === ind
                                        ? item.value
                                        : res?.id_vendor_angkut,
                                  })
                                );
                                setValues({
                                  ...values,
                                  detail: dataVendorAngkut,
                                });
                              }}
                              option={dropdownVendor.map((val) => {
                                return {
                                  value: val.id_vendor,
                                  label: val.nama_vendor,
                                };
                              })}
                              defaultValue={{
                                value: val.id_vendor_angkut ?? '',
                                label: val.id_vendor_angkut
                                  ? dropdownVendor.find(
                                      (item) =>
                                        item.id_vendor === val.id_vendor_angkut
                                    )?.nama_vendor
                                  : 'Pilih Vendor Angkut',
                              }}
                            />
                          </Td>
                          <Td>
                            <ReactNumberFormat
                              value={parseFloat(
                                val?.harga_kesepakatan_biaya_angkut ?? 0
                              )}
                              onChange={(e) => {
                                const valueHargaAngkut = values?.detail?.map(
                                  (item, ind) => ({
                                    ...item,
                                    harga_kesepakatan_biaya_angkut:
                                      index === ind
                                        ? String(e ?? 0)
                                        : item?.harga_kesepakatan_biaya_angkut,
                                  })
                                );

                                setValues({
                                  ...values,
                                  detail: valueHargaAngkut,
                                });
                              }}
                            />
                          </Td>
                          <Td>
                            {
                              RupiahConvert(parseFloat(jumlah).toString())
                                .getWithComa
                            }
                          </Td>
                          <Td>
                            <ButtonGroup>
                              <ActionButton
                                size="sm"
                                variant="danger"
                                text={<IoTrashOutline />}
                                onClick={() => {
                                  const filterData = values?.detail?.filter(
                                    (data) => data !== val
                                  );
                                  console.log('test', filterData);
                                  setValues({
                                    ...values,
                                    detail: filterData,
                                  });
                                }}
                              />
                              <ActionButton
                                size="sm"
                                text={<FiRefreshCcw />}
                                onClick={() => {
                                  setValues({
                                    ...values,
                                    detail: values.detail.map((res, idx) =>
                                      index === idx
                                        ? {
                                            ...res,
                                            id_vendor_angkut: null,
                                            harga_kesepakatan_biaya_angkut:
                                              null,
                                          }
                                        : res
                                    ),
                                  });
                                }}
                              />
                            </ButtonGroup>
                          </Td>
                        </Tr>
                      );
                    })}
                    <TableFooterItems
                      text="Total"
                      value={
                        RupiahConvert(parseFloat(total).toString()).getWithComa
                      }
                    />
                    <Tr>
                      <Td colSpan={9} className="text-right">
                        <div className="d-flex align-items-center justify-content-end text-nowrap">
                          <b className="mr-2">Diskon (%)</b>
                          <div className="d-flex justify-content-center align-items-center">
                            <div style={{ width: '70px' }}>
                              <ReactNumberFormat
                                value={parseFloat(values.diskon ?? 0)}
                                onChange={(e) => setFieldValue('diskon', e)}
                              />
                            </div>
                          </div>
                        </div>
                      </Td>
                      <Td textRight colSpan={3}>
                        <div className="text-right">
                          <b>
                            {RupiahConvert(nilaiDiskon.toString()).getWithComa}
                          </b>
                        </div>
                      </Td>
                    </Tr>
                    <TableFooterItems
                      text="Total Setelah Diskon"
                      value={
                        RupiahConvert(setelahDiskon.toString()).getWithComa
                      }
                    />
                    <Tr>
                      <Td colSpan={9} className="text-right">
                        <div className="d-flex align-items-center justify-content-end text-nowrap">
                          <b className="mr-2">PPN(%)</b>

                          <div className="d-flex justify-content-center align-items-center">
                            <div style={{ width: '70px' }}>
                              <ReactNumberFormat
                                value={parseFloat(values.ppn ?? 0)}
                                onChange={(e) => setFieldValue('ppn', e)}
                              />
                            </div>
                          </div>
                        </div>
                      </Td>
                      <Td textRight colSpan={3}>
                        <div className="text-right">
                          <b>
                            {RupiahConvert(nilaiPPN.toString()).getWithComa}
                          </b>
                        </div>
                      </Td>
                    </Tr>
                    <TableFooterItems
                      text="Total Setelah PPN"
                      value={RupiahConvert(setelahPPN.toString()).getWithComa}
                    />
                    <TableFooterItems
                      text="Total Biaya Angkut"
                      value={
                        RupiahConvert(totalBiayaAngkut.toString()).getWithComa
                      }
                    />
                    <TableFooterItems
                      text="Grand Total"
                      value={RupiahConvert(grandTotal.toString()).getWithComa}
                    />
                    <Tr>
                      <Td colSpan={9} className="text-right">
                        <div className="d-flex align-items-center justify-content-end text-nowrap">
                          <b className="mr-2">DP(%)</b>

                          <div className="d-flex justify-content-center align-items-center">
                            <div style={{ width: '70px' }}>
                              <ReactNumberFormat
                                value={parseFloat(values.dp ?? 0)}
                                onChange={(e) => setFieldValue('dp', e)}
                              />
                            </div>
                          </div>
                        </div>
                      </Td>
                      <Td textRight colSpan={3}>
                        <div className="text-right">
                          <b>{RupiahConvert(nilaiDP.toString()).getWithComa}</b>
                        </div>
                      </Td>
                    </Tr>
                  </TBody>
                </Table>
              </>
            ) : (
              <DataStatus text="Tidak ada data" />
            )
          ) : (
            <DataStatus text="Tidak ada data" />
          )}
          <hr />
        </>
      );
    };

    const FormCard = () => {
      const InfoItem = ({ title1, value1, title2, value2 }) => (
        <div className="mb-2">
          <small>{title1}</small>
          <br />
          <b>{value1}</b>
          <div className="mt-1" />
          <small>{title2}</small>
          <br />
          <b>{value2}</b>
        </div>
      );

      return (
        <Card className="mt-4 mb-5">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Catatan Purchase Order</b>
          </Card.Header>
          <Card.Body>
            <Row>
              {dataPengaju.map((val, index) => (
                <Col sm>
                  <InfoItem
                    title1={
                      val.status_approval === 'VER' ||
                      val.status_approval === 'REV'
                        ? `Pemeriksa ${
                            val.approval_level !== '0' ? val.approval_level : ''
                          }`
                        : val.status_approval === 'APP'
                        ? 'Pengesah'
                        : val.status_approval === 'PEN'
                        ? 'Pembuat'
                        : 'Di Tolak Oleh'
                    }
                    value1={val.nama_karyawan ?? '-'}
                    title2="Catatan"
                    value2={val.catatan ?? '-'}
                  />
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Card>
      );
    };

    const ButtonSection = ({ isSubmitting }) => (
      <div className="d-flex justify-content-end my-3">
        <ActionButton
          type="submit"
          variant="success"
          text="Ubah Data Purchase Order"
          loading={isSubmitting}
        />
      </div>
    );

    return (
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={formSubmitHandler}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleChange,
          handleSubmit,
          setValues,
        }) => (
          <form onSubmit={handleSubmit}>
            <InfoSection />
            <FormSection
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />
            <ListItemSeleksiVendor
              dataListItemPurchaseOrder={dataListItemPurchaseOrder}
              setDataListItemPurchaseOrder={setDataListItemPurchaseOrder}
            />
            <ListItemPurchaseOrderSection
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              dataListItemPurchaseOrder={dataListItemPurchaseOrder}
              setDataListItemPurchaseOrder={setDataListItemPurchaseOrder}
              setValues={setValues}
            />
            {dataPengaju.length > 1 && <FormCard />}
            {values?.detail && values?.detail.length > 0 && (
              <ButtonSection isSubmitting={isSubmitting} />
            )}
          </form>
        )}
      </Formik>
    );
  };

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <b>Ubah Data Purchase Order</b>
        <BackButton
          onClick={() =>
            history.push('/transaksi/purchase-order', { ...routerState })
          }
        />
      </Card.Header>
      <Card.Body>
        <Alert
          showCloseButton
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() =>
            setAlertConfig({
              ...alertConfig,
              show: false,
            })
          }
        />
        {isPageLoading ? (
          <DataStatus loading={true} text="Memuat data . . ." />
        ) : isFetchingFailed ? (
          <DataStatus text="Data gagal dimuat, tidak dapat mengubah data!" />
        ) : (
          <PageContent />
        )}
      </Card.Body>
    </Card>
  );
};

export default UbahPurchaseOrder;
