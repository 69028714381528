import React, { useState, useEffect } from 'react';
import { Card, Col, Modal, Nav, Row, Tab } from 'react-bootstrap';
import TabGambar from './TabGambar';
import TabFile from './TabFile';
import { DateConvert, DecimalConvert, RupiahConvert } from 'utilities';
import { Formik } from 'formik';

import Axios from 'axios';
import { DataStatus, InfoItemHorizontal } from 'components';
import { PurchaseOrderApi } from 'api';

export const ModalSeleksiVendor = ({ setModalConfig, modalConfig }) => {
  const [dataDetailModal, setDataDetailModal] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalAlertConfig, setModalAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getDetailModal = () => {
    Axios.all([
      PurchaseOrderApi.getSingleSelesiVendor({
        id_seleksi_vendor: modalConfig?.id,
      }),
    ])
      .then(
        Axios.spread((res) => {
          setDataDetailModal(res?.data?.data);
        })
      )
      .catch(() =>
        setModalAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        })
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getDetailModal();
    return () => {};
  }, []);

  return (
    <>
      <Modal
        show={modalConfig.show}
        onHide={() => setModalConfig({ id: '', show: false })}
        size="md"
      >
        <Modal.Header closeButton>Detail Penawaran Vendor</Modal.Header>
        <Modal.Body>
          {loading ? (
            <DataStatus loading text="Memuat Data" />
          ) : (
            <>
              <InfoItemHorizontal
                label="Tgl. Seleksi Vendor"
                text={
                  dataDetailModal?.tgl_seleksi_vendor
                    ? DateConvert(new Date(dataDetailModal?.tgl_seleksi_vendor))
                        .detail
                    : '-'
                }
              />
              <InfoItemHorizontal
                label="No. Seleksi Vendor"
                text={dataDetailModal?.no_seleksi_vendor ?? '-'}
              />
              <InfoItemHorizontal
                label="Item Barang"
                text={dataDetailModal?.detail[0]?.nama_item ?? '-'}
              />
              <InfoItemHorizontal
                label="Qty. Order"
                text={
                  DecimalConvert(
                    parseFloat(dataDetailModal?.detail[0]?.qty_order ?? 0)
                  ).getWithComa
                }
              />
              <InfoItemHorizontal
                label="Harga Kesepakatan"
                text={
                  RupiahConvert(
                    parseFloat(
                      dataDetailModal?.detail[0]?.harga_kesepakatan ?? 0
                    ).toString()
                  ).getWithComa
                }
              />

              <Formik
                initialValues={{
                  ...dataDetailModal,
                  file: dataDetailModal?.file?.map((val) => val?.path_file),
                }}
              >
                <Card className="mt-3">
                  <Tab.Container defaultActiveKey="tabGambar">
                    <Card.Header className="d-flex justify-content-between align-items-center">
                      <Nav variant="tabs">
                        <Nav.Item>
                          <Nav.Link eventKey="tabGambar">Gambar</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="tabFile">File</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Card.Header>
                    <Tab.Content>
                      <Tab.Pane eventKey="tabGambar">
                        <div className="m-2">
                          <TabGambar readOnly />
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="tabFile">
                        <div className="m-2">
                          <TabFile readOnly />
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </Card>
              </Formik>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
