import { ButtonCreate, ButtonUpdate } from '@bhawanadevteam/react-core';
import {
  BackButton,
  DatePicker,
  InfoItemHorizontal,
  InfoItemVertical,
  Input,
  InputCurrency,
  NumberFormat,
  SelectSearch,
} from 'components';
import { useFormikContext } from 'formik';
import React from 'react';
import { Card, Col, Modal, Nav, Row, Tab } from 'react-bootstrap';
import { useQuery } from 'react-query';
import TabGambar from './TabGambar';
import TabFile from './TabFile';
import { DateConvert, RupiahConvert } from 'utilities';
import SeleksiVendorAsetApi from '../__SeleksiVendorAsetApi__';

const DetailModal = ({ data }) => {
  console.log(data);
  return (
    <Row className="mb-3">
      <Col>
        <InfoItemHorizontal
          label="Tgl. Permintaan Aset"
          text={
            data?.tglPermintaanAset
              ? DateConvert(new Date(data?.tglPermintaanAset)).detail
              : '-'
          }
          width={130}
        />
        <InfoItemHorizontal
          label="No. Permintaan Aset"
          text={data?.noPermintaanAset ?? '-'}
          width={130}
        />
        <InfoItemHorizontal
          label="Catatan Permintaan"
          text={data?.catatanPermintaan ?? '-'}
          width={130}
        />
        <InfoItemHorizontal
          label="Entitas Aset"
          text={data?.nama_vendor ?? data?.namaEntitasAset}
          width={130}
        />
        <InfoItemHorizontal
          label="Grup Aset"
          text={data?.namaGrupAset ?? '-'}
          width={130}
        />
        <InfoItemHorizontal
          label="Kategori Aset"
          text={data?.namaKategoriAset ?? '-'}
          width={130}
        />
        <InfoItemHorizontal
          label="Jenis Aset"
          text={data?.namaJenisAset ?? '-'}
          width={130}
        />
        <InfoItemHorizontal
          label="Nama Aset"
          text={data?.namaAset ?? '-'}
          width={130}
        />
      </Col>
      <Col>
        <InfoItemHorizontal
          label="Keperluan"
          text={data?.keperluanLabel ?? '-'}
          width={130}
        />
        <InfoItemHorizontal
          label="Metode Pengadaan"
          text={data?.metodePengadaanLabel ?? '-'}
          width={130}
        />
        <InfoItemHorizontal
          label="Unit Produksi"
          text={data?.namaUnitProduksi ?? '-'}
          width={130}
        />
        <InfoItemHorizontal
          label="Keterangan"
          text={data?.keterangan ?? '-'}
          width={130}
        />
      </Col>
    </Row>
  );
};

const ModalFormRead = () => {
  const { values } = useFormikContext();
  return (
    <Row>
      <Col>
        <InfoItemVertical
          label="Tgl. Seleksi Vendor Aset"
          text={
            values?.tgl_seleksi_vendor_aset
              ? DateConvert(new Date(values?.tgl_seleksi_vendor_aset)).detail
              : '-'
          }
        />
        <InfoItemVertical label="Vendor" text={values?.nama_vendor ?? '-'} />
      </Col>
      <Col>
        <InfoItemVertical
          label="No. Seleksi Vendor Aset"
          text={values?.no_seleksi_vendor_aset ?? '-'}
        />
        <InfoItemVertical
          label="Harga Kesepakatan"
          text={
            RupiahConvert(parseFloat(values?.harga_kesepakatan ?? 0).toString())
              .getWithComa
          }
        />
      </Col>
    </Row>
  );
};

export const ModalForm = ({ setModalConfig, modalConfig, action }) => {
  const {
    values,
    setValues,
    setFieldValue,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
  } = useFormikContext();

  const { data: dropdownVendor, isFetching: loadingDropdownVendor } = useQuery(
    ['vendor', 'dropdown'],
    () => SeleksiVendorAsetApi.getDropdownVendor()
  );

  const { isFetching: loadingKode } = useQuery(
    ['seleksiVendorAset', 'kode', values?.tgl_seleksi_vendor_aset],
    () =>
      SeleksiVendorAsetApi.generateNumber({
        tanggal: values?.tgl_seleksi_vendor_aset,
      }).then((val) =>
        setValues({ ...values, no_seleksi_vendor_aset: val?.data?.data })
      ),
    {
      enabled: !!values?.tgl_seleksi_vendor_aset && action === 'create',
    }
  );

  return (
    <Modal
      show={modalConfig.show}
      onHide={() => setModalConfig({ show: false, data: {}, type: '' })}
      scrollable
      size="lg"
    >
      <Modal.Header closeButton>Tambah Data Seleksi Vendor</Modal.Header>
      <Modal.Body>
        <DetailModal data={modalConfig?.data ?? {}} />
        <Card>
          <Tab.Container defaultActiveKey="tabGambar">
            <Card.Header className="d-flex justify-content-between align-items-center">
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="tabGambar">Gambar</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tabFile">File</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>
            <Tab.Content>
              <Tab.Pane eventKey="tabGambar">
                <div className="m-2">
                  <TabGambar readOnly data={modalConfig?.data?.file ?? []} />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="tabFile">
                <div className="m-2">
                  <TabFile readOnly data={modalConfig?.data?.file ?? []} />
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Card>

        <hr />
        {modalConfig.type === 'detail' ? (
          <ModalFormRead />
        ) : (
          <Row>
            <Col>
              <DatePicker
                label="Tgl. Seleksi Vendor Aset"
                selected={
                  values?.tgl_seleksi_vendor_aset
                    ? new Date(values?.tgl_seleksi_vendor_aset)
                    : ''
                }
                onChange={(date) => {
                  setFieldValue(
                    'tgl_seleksi_vendor_aset',
                    DateConvert(date).default
                  );
                }}
                error={
                  errors.tgl_seleksi_vendor_aset &&
                  touched.tgl_seleksi_vendor_aset &&
                  true
                }
                errorText={errors.tgl_seleksi_vendor_aset}
              />
              <SelectSearch
                label="Vendor"
                option={dropdownVendor}
                loading={loadingDropdownVendor}
                name="id_vendor"
                placeholder="Pilih vendor"
                defaultValue={
                  values?.id_vendor
                    ? {
                        value: values?.id_vendor,
                        label: values?.nama_vendor,
                      }
                    : undefined
                }
                onChange={(val) =>
                  setValues({
                    ...values,
                    id_vendor: val.value,
                    nama_vendor: val.label,
                  })
                }
                error={errors.id_vendor && touched.id_vendor && true}
                errorText={
                  errors.id_vendor && touched.id_vendor && errors.id_vendor
                }
              />
            </Col>
            <Col>
              <Input
                label="No. Seleksi Vendor Aset"
                type="text"
                readOnly
                placeholder="Pilih tanggal terlebih dahulu"
                value={
                  loadingKode ? 'Memuat...' : values?.no_seleksi_vendor_aset
                }
              />
              <InputCurrency
                label="Harga Kesepakatan"
                placeholder="Masukan harga kesepakatan"
                value={parseFloat(values?.harga_kesepakatan)}
                onChange={(val) => setFieldValue('harga_kesepakatan', val)}
                error={
                  errors.harga_kesepakatan && touched.harga_kesepakatan && true
                }
                errorText={
                  errors.harga_kesepakatan &&
                  touched.harga_kesepakatan &&
                  errors.harga_kesepakatan
                }
              />
            </Col>
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <BackButton
          size="sm"
          onClick={() =>
            setModalConfig({ ...modalConfig, show: false, id: undefined })
          }
        />
        {modalConfig?.type === 'add' && (
          <ButtonCreate onClick={handleSubmit} loading={isSubmitting} />
        )}
        {modalConfig?.type === 'update' && (
          <ButtonUpdate
            variant="success"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};
