import Services from "services"

class TransferSubkonJobMixApi {
    page(params) {
        return Services.get("/transfer_produksi_subkon/page", { params })
    }

    single(params) {
        return Services.get(`/transfer_produksi_subkon/single`, { params })
    }

    produksi_page(params) {
        return Services.get("/transfer_produksi_subkon/produksi_page", { params })
    }

    produksi_single(value) {
        return Services.get(`/transfer_produksi_subkon/produksi_single?id_jobmix=${value}`)
    }

    jobmix_single(value) {
        return Services.get(`/transfer_produksi_subkon/jobmix_single?id_jobmix=${value}`)
    }

    histori(value) {
        return Services.get(`/transfer_produksi_subkon/histori?id_jobmix=${value}`)
    }

    getSinglePermintaan(params) {
        return Services.get("/transfer_produksi_subkon/permintaan_single", { params })
    }

    no_baru(value) {
        return Services.get(`/transfer_produksi_subkon/no_baru?tanggal=${value}`)
    }

    dropdown(label, value) {
        return Services.get(`/transfer_produksi_${label}/dropdown?tipe=${value}`)
    }

    getDropdownVendor() {
        return Services.get("/dropdown/vendor")
    }

    save(data) {
        return Services.post("/transfer_produksi_subkon", data)
    }
}

export default new TransferSubkonJobMixApi();
