import {
  CRUDLayout,
  ReadButton,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
} from "components";
import { useState } from "react";
import {
  DateConvert,
  DecimalConvert,
  RupiahConvert,
  TableNumber as PageNumber,
} from "utilities";
import { TransferModal } from "../modals";

const TableTransfer = ({ isPre, data, dataFilter, title }) => {
  // States
  const [modal, setModal] = useState(false);
  const [dataModal, setDataModal] = useState({});

  const toggleModal = () => setModal(!modal);

  const HeaderAksi = () => <ThFixed>Aksi</ThFixed>;
  const BodyAksi = ({ val }) => (
    <TdFixed>
      <ReadButton
        size="sm"
        onClick={() => {
          setDataModal(val);
          toggleModal();
        }}
      />
    </TdFixed>
  );

  return (
    <>
      <CRUDLayout.Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            {isPre && <HeaderAksi />}
            <ThFixed>Informasi Transfer Upah Produksi</ThFixed>
            <ThFixed>Informasi Permintaan Produksi</ThFixed>
            <Th>Item Upah</Th>
            <ThFixed>Qty. Transfer</ThFixed>
            <Th>Vendor</Th>
            <ThFixed>Harga Satuan</ThFixed>
            <ThFixed>Total Harga</ThFixed>
            {!isPre && <HeaderAksi />}
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => {
            // Variables
            const harga_satuan = parseInt(val.harga_satuan ?? 0);
            const qty_transfer = parseFloat(val.qty_transfer ?? 0);
            const total_harga = harga_satuan * qty_transfer;

            return (
              <Tr key={index}>
                {isPre ? (
                  <TdFixed>
                    {PageNumber(dataFilter.page, dataFilter.per_page, index)}
                  </TdFixed>
                ) : (
                  <TdFixed>{index + 1}</TdFixed>
                )}
                {isPre && <BodyAksi val={val} />}
                <Td>
                  <div>
                    {val.tgl_transfer_produksi_upah
                      ? DateConvert(new Date(val.tgl_transfer_produksi_upah))
                          .defaultDMY
                      : "-"}
                  </div>
                  <div>{val.no_transfer_produksi_upah ?? "-"}</div>
                </Td>
                <Td>
                  <div>
                    {val.tgl_permintaan_produksi_upah
                      ? DateConvert(new Date(val.tgl_permintaan_produksi_upah))
                          .defaultDMY
                      : "-"}
                  </div>
                  <div>{val.no_permintaan_produksi_upah ?? "-"}</div>
                </Td>
                <Td>{val.nama_item ?? "-"}</Td>
                <Td className="text-right">{`${
                  DecimalConvert(qty_transfer).getWithComa
                } ${val.kode_satuan ?? ""}`}</Td>
                <Td>{val.nama_vendor ?? "-"}</Td>
                <Td className="text-nowrap" textRight>
                  {RupiahConvert(String(harga_satuan)).getWithComa}
                </Td>
                <Td className="text-nowrap" textRight>
                  {RupiahConvert(String(total_harga)).getWithComa}
                </Td>
                {!isPre && <BodyAksi val={val} />}
              </Tr>
            );
          })}
        </TBody>
      </CRUDLayout.Table>

      {modal && (
        <TransferModal toggle={toggleModal} data={dataModal} title={title} />
      )}
    </>
  );
};

export default TableTransfer;
