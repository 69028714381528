import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Alert, ButtonGroup } from "react-bootstrap";
import {
  CRUDLayout,
  FilterButton,
  InputSearch,
  Table,
  BackButton,
  Pagination,
  Td,
  Th,
  ThFixed,
  ActionButton,
  DataStatus,
} from "components";
import { DateConvert, DecimalConvert, TableNumber } from "utilities";

import { IoClose } from "react-icons/io5";
import { ModalFilter } from "./section";

import { ClosePurchaseOrderApi } from "api";

const SalesOrderList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();

  const createAlert = location?.state?.alert?.variant;

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [searchTime, setSearchTime] = useState(null);
  const [dataSalesOrder, setDataSalesOrder] = useState([]);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.closeSalesOrder?.filter?.active,
      tgl_purchase_order_mulai:
        location?.state?.closeSalesOrder?.filter?.tgl_purchase_order_mulai,
      tgl_purchase_order_selesai:
        location?.state?.closeSalesOrder?.filter?.tgl_purchase_order_selesai,
      id_vendor: location?.state?.closeSalesOrder?.filter?.id_vendor,
      tgl_pengiriman: location?.state?.closeSalesOrder?.filter?.tgl_pengiriman,
    },
    pagination: {
      page: location?.state?.closeSalesOrder?.filter?.page ?? "1",
      dataLength: location?.state?.closeSalesOrder?.filter?.dataLength ?? "10",
      totalPage: location?.state?.closeSalesOrder?.filter?.totalPage ?? "1",
      dataCount: location?.state?.closeSalesOrder?.filter?.dataCount ?? "0",
    },
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    ClosePurchaseOrderApi.getPO({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_purchase_order_mulai: dataFilter?.filter?.tgl_purchase_order_mulai,
      tgl_purchase_order_selesai:
        dataFilter?.filter?.tgl_purchase_order_selesai,
      id_vendor: dataFilter?.filter?.id_vendor,
      tgl_pengiriman_mulai: dataFilter?.filter?.tgl_pengiriman_mulai,
      tgl_pengiriman_selesai: dataFilter?.filter?.tgl_pengiriman_selesai,
    })
      .then((data) => {
        setDataSalesOrder(data?.data?.data ?? []);
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: data?.data?.data_count,
            totalPage: data?.data?.total_page,
          },
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;
    if (searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: e.target.value,
        });
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            page: "1",
            dataLength: "10",
            totalPage: "1",
            dataCount: "0",
          },
        });
        setAlertConfig({
          show: key ? true : false,
          variant: "primary",
          text: "Hasil dari pencarian: " + key,
        });
      }, 750)
    );
  };

  useEffect(() => {
    setNavbarTitle("Purchase Close Order");
    if (createAlert !== undefined) {
      // setShowAlert(true);
      setAlertConfig({
        variant: location?.state?.alert?.variant,
        text: location?.state?.alert?.text.alert,
        show: true,
      });

      getInitialData();
      history.replace((createAlert = undefined));
    } else {
      return getInitialData(false);
    }
  }, [
    setNavbarTitle,
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_purchase_order_mulai,
    dataFilter?.filter?.tgl_purchase_order_selesai,
    dataFilter?.filter?.id_vendor,
    dataFilter?.filter?.tgl_pengiriman_mulai,
    dataFilter?.filter?.tgl_pengiriman_selesai,
  ]);

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <thead>
          <tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Informasi Purchase Order</ThFixed>
            <Th>Vendor</Th>
            <Th>Tgl. Pengiriman</Th>
            <ThFixed>Aksi</ThFixed>
          </tr>
        </thead>
        <tbody>
          {dataSalesOrder.map((val, index) => (
            <tr key={index}>
              <Td textCenter>
                {TableNumber(
                  dataFilter?.pagination?.page,
                  dataFilter?.pagination?.dataLength,
                  index
                )}
              </Td>
              <Td className="text-nowrap">
                <div>
                  {val.tgl_purchase_order
                    ? DateConvert(new Date(val.tgl_purchase_order)).defaultDMY
                    : "-"}
                </div>
                <div>{val.no_purchase_order ?? "-"}</div>
              </Td>
              <Td className="text-nowrap">{val.nama_vendor ?? "-"}</Td>
              <Td>
                {val.tgl_pengiriman
                  ? DateConvert(new Date(val.tgl_pengiriman)).defaultDMY
                  : "-"}
              </Td>
              <Td>
                <ButtonGroup size="sm">
                  <ActionButton
                    variant="danger"
                    size="sm"
                    text={<IoClose />}
                    onClick={() =>
                      history.push(
                        "/transaksi/close-purchase-order/purchase-order/tambah/" +
                          val.id_purchase_order,
                        {
                          ...location?.state,
                          produksi: dataFilter,
                          is_spk: val.is_spk,
                        }
                      )
                    }
                  />
                </ButtonGroup>
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
    );

    return (
      <>
        <b>List Data Purchase Order Yang Siap di Close</b>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength -
              dataFilter?.pagination?.dataLength +
              1
            }
            dataPage={
              dataFilter?.pagination?.dataCount <
              dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page *
                  dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                },
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                },
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              // value={searchConfig.key}
              onChange={onInputSearchChange}
            />
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.goBack()} size="sm" />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}

      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  );
};

export default SalesOrderList;
