import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { Modal, ModalBody } from "react-bootstrap";
import * as Yup from "yup";
import Axios from "axios";
import {
  CRUDLayout,
  Input,
  InputSearch,
  SelectSearch,
  CreateButton,
  ActionButton,
  DataStatus,
  CreateModal,
  UpdateModal,
  DeleteModal,
  UpdateButton,
  DeleteButton,
  Select,
  Alert,
  Switch,
  Pagination,
  THead,
  Tr,
  ThFixed,
  Th,
  TBody,
  TdFixed,
  Td,
  ExportButton,
  ReadModal,
  ReadButton
} from "components";
import { MasterItemUpahApi } from "api";
import { TableNumber } from "utilities";
import FileSaver from "file-saver";

const RegistrasiUpah = ({ setNavbarTitle }) => {
  // Title
  const title = "Item Upah";

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(true);

  // STATE SEARCHING
  const [isSearching, setIsSearching] = useState(false);
  // const [searchKey, setSearchKey] = useState("");

  // MODALS SHOW STATE
  const [isDetailForm, setIsDetailForm] = useState(false); // MODAL DETAIL STATE
  const [isCreateForm, setIsCreateForm] = useState(false); // MODAL TAMBAH STATE
  const [isUpdateForm, setIsUpdateForm] = useState(false); // MODAL UPDATE STATE
  const [isDeleteData, setIsDeleteData] = useState(false); // MODAL HAPUS STATE

  // STATE DATA UPAH
  const [data, setData] = useState([]);
  // STATE UNTUK MENAMPUNG DATA FORM YANG DIPILIH
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [totalData, setTotalData] = useState("");
  const [detailData, setDetailData] = useState([])
  // STATE MENAMPUNG DATA YANG AKAN DI UPDATE
  const [updateData, setUpdateData] = useState([]);
  // STATE MENAMPUNG DATA YANG AKAN DI HAPUS
  const [deleteData, setDeleteData] = useState([]);

  // STATE MENAMPUNG GENERATE KODE ITEM
  const [kodeItem, setKodeItem] = useState([]);
  // STATE MENAMPUNG DATA BUASO SELECT
  const [buasoData, setBuasoData] = useState([]);
  // STATE MENAMPUNG DATA SATUAN SELECT SEARCH
  const [satuanData, setSatuanData] = useState([]);

  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false);
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [searchTime, setSearchTime] = useState(null);

  const { REACT_APP_EXPORT_BASE_URL } = process.env;

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);

    setShowAlert(false);

    Axios.all([
      MasterItemUpahApi.getPage(page, dataLength, searchConfig.key),
      MasterItemUpahApi.getBuaso(),
      MasterItemUpahApi.getSatuan(),
      MasterItemUpahApi.generateKodeItem(),
    ])
      .then(
        Axios.spread((res, buaso, satuan, kodeItem) => {
          setData(res.data.data);
          setBuasoData(buaso.data.data);
          setSatuanData(satuan.data.data);
          setKodeItem(kodeItem.data.data);
          setTotalPage(res.data.total_page);
          setTotalData(res.data.data_count);
        })
      )
      .catch((err) => alert(`Tidak dapat memuat data (${err})`))
      .finally(() => {
        // if (searchKey != "") {
        //   setAlertConfig({
        //     variant: "primary",
        //     text: `Hasil Pencarian : ${searchKey}`,
        //   });
        //   setShowAlert(true);
        // }
        setIsLoading(false);
      });
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value
    if(searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: e.target.value
        });
        setAlertConfig({
          variant: "primary",
          text: `Hasil Pencarian : ` + key,
        });
        setShowAlert(key ? true : false);
      }, 750)
    )
  }

  // FUNCTION UBAH SWITCH SHOW/HIDE STATUS
  const changeDataStatus = (status, id) => {
    // setIsLoading(true);
    setShowAlert(false);

    const value = {
      id_item_buaso: id,
    };

    const onLoadedSuccess = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: "primary",
        text: "Ubah status data berhasil",
      });
      setShowAlert(true);
    };

    const onLoadedFailed = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: "danger",
        text: "Ubah status data gagal",
      });
      setShowAlert(true);
    };

    status === true
      ? MasterItemUpahApi.show(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData())
      : MasterItemUpahApi.hide(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData());
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title); // SET JUDUL NAVBAR
    getData(); // GET DATA DARI SERVER

    return () => {
      setIsLoading(false);
      setIsSearching(false);
    };
  }, [setNavbarTitle, page, dataLength, searchConfig.key]);

  // FORMIK VALIDATION SCHEMA DENGAN YUP VALIDATION
  const formValidationSchema = Yup.object().shape({
    nama_item: Yup.string()
      // .test("checkNama", "Nama tidak dapat digunakan", (value) =>
      //   MasterItemUpahApi.single("nama_item", value)
      //     .then(() => false)
      //     .catch(() => true)
      // )
      .required("Masukkan Nama Item Upah"),
    // keterangan: Yup.string().required("Masukkan Keterangan"),
    id_satuan: Yup.string().required("Pilih Satuan"),
  });
  
  // MODAL DETAIL COMPONENT
  const DetailModal = () => {
    const ShowData = ({ title, text }) => (
      <div className="d-flex flex-row">
        <div className="text-secondary mb-2 mr-3" style={{ width: 170 }}>
          {title}
        </div>
        <span>:</span>
        <div
          className="text-dark"
          style={{
            paddingLeft: 5,
          }}
        >
          {text ? text : "-"}
        </div>
      </div>
    )
    return (
      <ReadModal show={isDetailForm} onHide={() => setIsDetailForm(false)} title={title}>
        <ModalBody>
          <ShowData
            title="Kode Item Upah"
            text={detailData.kode_item}
          />
          <ShowData
            title="Nama Item Upah"
            text={detailData.nama_item}
          />
          <ShowData
            title="Satuan Pakai"
            text={detailData.nama_satuan}
          />
        </ModalBody>
      </ReadModal>
    )
  }

  // MODAL TAMBAH COMPONENT
  const TambahModal = () => {
    // FORMIK INITIAL VALUES
    const formInitialValues = {
      id_buaso: "2", // SET ID BUASO UPAH
      kode_item: kodeItem,
      nama_item: "",
      keterangan: "",
      id_satuan: "",
    };

    // KIRIM DATA BARU KE SERVER
    const formSubmitHandler = (values) => {
      setIsCreateForm(false);

      MasterItemUpahApi.create(values)
        .then((res) => {
          // SUCCESS ALERT
          setAlertConfig({
            variant: "primary",
            text: "Tambah data berhasil!",
          });
        })
        .catch((err) => {
          // const errMsg = Object.values(err.response?.data?.error) ?? []
          // ERROR ALERT
          setAlertConfig({
            variant: "danger",
            text: `Tambah data gagal! (${err.response.data.message})`,
            // text: `Tambah data gagal! <ul> ${errMsg.map(e => `<li>${e}</li>`).join("")} </ul>`
          });
        })
        .finally(() => {
          // CLOSE MODAL
          setIsCreateForm(false);
          // TAMPILKAN ALERT
          setShowAlert(true);
          // FETCH DATA DARI SERVER
          getData();
        });
    };

    return (
      <CreateModal show={isCreateForm} onHide={() => setIsCreateForm(false)} title={title}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Input
                  label="BUASO"
                  type="text"
                  name="id_buaso"
                  // placeholder="Kode Item"
                  value="20 - Upah"
                  readOnly={true}
                  onChange={handleChange}
                />

                <Input
                  label="Kode Upah"
                  type="text"
                  name="kode_item"
                  placeholder="Kode Item"
                  value={values.kode_item}
                  // readOnly={true}
                  onChange={handleChange}
                />

                <Input
                  label="Nama Item Upah"
                  type="text"
                  name="nama_item"
                  placeholder="Item Upah"
                  value={values.nama_item}
                  onChange={handleChange}
                  error={errors.nama_item && touched.nama_item && true}
                  errorText={errors.nama_item}
                />

                <Input
                  label="Keterangan"
                  type="text"
                  name="keterangan"
                  placeholder="Keterangan"
                  value={values.keterangan}
                  onChange={handleChange}
                  error={errors.keterangan && touched.keterangan && true}
                  errorText={errors.keterangan}
                />

                <SelectSearch
                  label="Satuan Pakai"
                  name="id_satuan"
                  placeholder="Pilih Satuan Pakai"
                  onChange={(val) => {
                    setFieldValue("id_satuan", val.value);
                  }}
                  // MAPPING OPTIONS
                  // OPTIONS BERUPA VALUE DAN LABEL
                  option={satuanData.map((val) => {
                    return {
                      value: val.id_satuan,
                      label: val.nama_satuan,
                    };
                  })}
                  defaultValue="" // default value harus berupa: {{ label: 'masukan label', value: 'masukan value' }}
                  error={errors.id_satuan && touched.id_satuan && true}
                  errorText={errors.id_satuan && touched.id_satuan && errors.id_satuan}
                />
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex justify-content-end">
                  <ActionButton
                    type="submit"
                    variant="primary"
                    text="Tambah"
                    className="mt-2 px-4"
                    loading={isSubmitting}
                  />
                </div>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </CreateModal>
    );
  };

  // MODAL UBAH COMPONENT
  const UbahModal = () => {
    const formInitialValues = {
      id_buaso: updateData.id_buaso, // SET ID BUASO UPAH
      kode_item: updateData.kode_item,
      nama_item: updateData.nama_item,
      keterangan: updateData.keterangan,
      id_satuan: updateData.id_satuan,
    };

    // KIRIM UPDATE DATA KE SERVER
    const formSubmitHandler = (values) => {
      const finalValues = {
        id_item_buaso: updateData.id_item_buaso,
        ...values,
      };
      MasterItemUpahApi.update(finalValues)
        .then((res) => {
          // SUCCESS ALERT
          setAlertConfig({
            variant: "primary",
            text: "Ubah data berhasil!",
          });
        })
        .catch((err) => {
          // const errMsg = Object.values(err.response?.data?.error) ?? []
          // ERROR ALERT
          setAlertConfig({
            variant: "danger",
            text: `Ubah data gagal! (${err.response.data.message})`,
            // text: `Ubah data gagal! <ul> ${errMsg.map(e => `<li>${e}</li>`).join("")} </ul>`
          });
        })
        .finally(() => {
          // CLOSE UPDATE MODAL FORM
          setIsUpdateForm(false);
          // SHOW ALERT
          setShowAlert(true);
          // FETCH DATA DARI SERVER
          getData();
        });
    };

    return (
      <UpdateModal show={isUpdateForm} onHide={() => setIsUpdateForm(false)} title={title}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Input
                  label="BUASO"
                  type="text"
                  name="id_buaso"
                  // placeholder=""
                  value="20 - Upah"
                  readOnly={true}
                  onChange={handleChange}
                />

                <Input
                  label="Kode Upah"
                  type="text"
                  name="kode_item"
                  placeholder="Kode Item"
                  value={values.kode_item}
                  // readOnly={true}
                  onChange={handleChange}
                />

                <Input
                  label="Nama Item Upah"
                  type="text"
                  name="nama_item"
                  placeholder="Item Upah"
                  value={values.nama_item}
                  onChange={handleChange}
                  error={errors.nama_item && touched.nama_item && true}
                  errorText={errors.nama_item}
                />

                <Input
                  label="Keterangan"
                  type="text"
                  name="keterangan"
                  placeholder="Keterangan"
                  value={values.keterangan}
                  onChange={handleChange}
                  error={errors.keterangan && touched.keterangan && true}
                  errorText={errors.keterangan}
                />

                <SelectSearch
                  label="Satuan Pakai"
                  name="id_satuan"
                  placeholder="Pilih Satuan Pakai"
                  onChange={(val) => {
                    setFieldValue("id_satuan", val.value);
                  }}
                  // MAPPING OPTIONS
                  // OPTIONS BERUPA VALUE DAN LABEL
                  option={satuanData.map((val) => {
                    return {
                      value: val.id_satuan,
                      label: val.nama_satuan,
                    };
                  })}
                  defaultValue={{
                    value: updateData.id_satuan ?? "",
                    label:
                      updateData.id_satuan === ""
                        ? "Pilih Satuan"
                        : satuanData.find((val) => val.id_satuan === updateData.id_satuan)
                            ?.nama_satuan ?? "Pilih Satuan",
                  }}
                  error={errors.id_satuan && touched.id_satuan && true}
                  errorText={errors.id_satuan && touched.id_satuan && errors.id_satuan}
                />
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex justify-content-end">
                  <ActionButton
                    type="submit"
                    variant="success"
                    text="Ubah"
                    className="mt-2 px-4"
                    loading={isSubmitting}
                  />
                </div>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </UpdateModal>
    );
  };

  // MODAL HAPUS COMPONENT
  const HapusModal = () => {
    // SET DATA ID YANG DIHAPUS
    const deleteValue = { id_item_buaso: deleteData.id_item_buaso };

    // MENANGANI DELETE BUTTON LOADING
    const [btnLoading, setBtnLoading] = useState(false);

    // DELETE DATA DARI SERVER
    const deleteDataHandler = () => {
      setBtnLoading(true);
      MasterItemUpahApi.delete(deleteValue)
        .then(() => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "primary",
            text: "Hapus data berhasil!",
          });
        })
        .catch((err) => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "danger",
            text: `Hapus data gagal!(${err.response.data.message})`,
          });
        })
        .finally(() => {
          // CLOSE MODAL
          setIsDeleteData(false);
          // TAMPIL ALERT
          setShowAlert(true);
          // FETCH DATA DARI SERVER
          getData();
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <div>Kode Upah : {deleteData.kode_item}</div>
        <div>Nama Item Upah : {deleteData.nama_item}</div>
      </DeleteModal>
    );
  };

  // TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Kode Item Upah</ThFixed>
              <Th>Nama Item Upah</Th>
              <Th width={130}>Satuan Pakai</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              return (
                <Tr key={val.id_item_buaso}>
                  <TdFixed>{TableNumber(page, dataLength, index)}</TdFixed>
                  <TdFixed>
                    <div className="d-flex justify-content-center">
                      <ReadButton
                        onClick={() => {
                          setDetailData(val)
                          setIsDetailForm(true)
                        }}
                      />
                      <UpdateButton
                        onClick={() => {
                          setUpdateData(val);
                          setIsUpdateForm(true);
                        }}
                      />
                      <DeleteButton
                        onClick={() => {
                          setDeleteData(val);
                          setIsDeleteData(true);
                        }}
                      />

                      <Switch
                        id={(index + 1).toString()}
                        checked={val.is_hidden ? false : true}
                        onChange={() => changeDataStatus(val.is_hidden, val.id_item_buaso)}
                      />
                    </div>
                  </TdFixed>
                  <TdFixed>{val.kode_item ?? "-"}</TdFixed>
                  <Td>{val.nama_item}</Td>
                  <Td>{val.nama_satuan}</Td>
                </Tr>
              );
            })}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={dataLength}
            dataPage={
              totalData <= 10
                ? data.length
                : data.map((res, index) => {
                    if (index == data.length - 1) {
                      return TableNumber(page, dataLength, index);
                    }
                  })
            }
            dataNumber={data.map((res, index) => {
              if (index == 0) {
                return TableNumber(page, dataLength, index);
              }
            })}
            dataCount={totalData}
            onDataLengthChange={(e) => {
              setDataLength(e.target.value);
              setPage(1);
            }}
            currentPage={page}
            totalPage={totalPage}
            onPaginationChange={({ selected }) => setPage(selected + 1)}
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={onInputSearchChange}
              // onSubmit={(e) => e.preventDefault()}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        {/* Button Section */}
        <CRUDLayout.HeadButtonSection>
          {/* Button Tambah */}
          <ExportButton
            onClick={() => {
              setIsLoading(true);
              MasterItemUpahApi.export()
                .then((val) => {
                  const urlFile = REACT_APP_EXPORT_BASE_URL + "/" + val.data.file;
                  FileSaver.saveAs(urlFile, val.data.file);
                })
                .catch((res) => alert(res))
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          />
          <CreateButton onClick={() => setIsCreateForm(true)} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : !Array.isArray(data) ? (
        <DataStatus text="Tidak dapat memuat data" />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {/* MODAL */}
      <DetailModal />
      <TambahModal />
      <UbahModal />
      <HapusModal />
    </CRUDLayout>
  );
};

export default RegistrasiUpah;
