// React
import React, { useState, useEffect } from "react";

// Component
import { Button, Card } from "react-bootstrap";
import {
  CRUDLayout,
  Tr,
  Th,
  Td,
  Alert,
  DataStatus,
  TdFixed,
  ThFixed,
  THead,
  TBody,
  DatePicker,
  ActionButton,
  SelectSearch,
} from "components";
import { DateConvert, DecimalConvert, RupiahConvert } from "utilities";

// Icon
import { IoDocumentTextOutline } from "react-icons/io5";

// File Saver
import FileSaver from "file-saver";

// API
import axios from "axios";
import { LaporanPurchaseOrderApi } from "api";

const LaporanPurchaseOrder = ({ setNavbarTitle }) => {
  const TODAY = new Date().toISOString().slice(0, 10);
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [form, setForm] = useState({
    tanggal_start: TODAY,
    tanggal_end: TODAY,
    nama_item: null,
    nama_vendor: null,
    tgl_jatuh_tempo: null,
  });
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
    show: false,
  });
  const [atribut, setAtribut] = useState({
    vendor: [],
    atribut: [],
  });

  // const mapDataFromServer = (data) => {
  //   const uniqNumber = []
  //   const mappedData = data.map(item => {
  //     if (!uniqNumber.includes(item.no_purchase_request)) {
  //       uniqNumber.push(item.no_purchase_request)
  //       return item
  //     }
  //     return Object({
  //       nama_item: item.nama_item,
  //       nama_satuan_beli: item.nama_satuan_beli,
  //       qty_order: item.qty_order,
  //       qty_diterima: item.qty_diterima,
  //       qty_sisa: item.qty_sisa,
  //       harga_kesepakatan: item.harga_kesepakatan,
  //       ppn: item.ppn,
  //       diskon: item.diskon,
  //     })
  //   })
  //   return mappedData
  // }

  const handleFetchReport = () => {
    setLoading(true);

    LaporanPurchaseOrderApi.getPage(form)
      .then(({ data }) => setData(data.data))
      .catch(() =>
        setAlertConfig({
          variant: "danger",
          text: "Tidak dapat memuat data!",
          show: true,
        })
      )
      .finally(() => setLoading(false));
  };

  const handleButtonExport = (tipe) => {
    const query = { ...form, tipe };

    LaporanPurchaseOrderApi.getExportVendor(query).then(({ data }) => {
      const ext = tipe === "excel" ? "xlsx" : "pdf";
      FileSaver.saveAs(
        data.data.replace("http://", "https://"),
        `Laporan_PO.${ext}`
      );
    });
  };

  useEffect(() => {
    setNavbarTitle("Laporan Monitoring Purchasing Order Vendor");

    const semua = { value: null, label: "Semua Data" };
    setPageLoading(true);
    axios
      .all([
        LaporanPurchaseOrderApi.getDropdown({ tipe: "vendor" }),
        LaporanPurchaseOrderApi.getDropdown({ tipe: "material" }),
      ])
      .then(
        axios.spread((vendor, material) => {
          const dataVendor = vendor?.data?.data ?? [];
          const dataMaterial = material?.data?.data ?? [];

          setAtribut({
            vendor: [
              {
                id_vendor: semua.value,
                nama_vendor: semua.label,
              },
              ...dataVendor,
            ],
            material: [
              {
                id_item_buaso: semua.value,
                nama_item: semua.label,
              },
              ...dataMaterial,
            ],
          });
        })
      )
      .finally(() => {
        setPageLoading(false);
      });
  }, [setNavbarTitle]);

  const ExportButton = ({
    variant = "outline-success",
    text = "Export EXCEL",
    onClick,
  }) => {
    return (
      <Button variant={variant} onClick={onClick} className="mx-1 mb-1">
        <IoDocumentTextOutline size={18} />
        <span className="ml-2 text-uppercase">{text}</span>
      </Button>
    );
  };

  const TableSection = () => {
    return (
      <div className="mt-3">
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <Th width={30}>No</Th>
              {/* <Th noPadding minWidth={100}>
                Tgl. PR
              </Th>
              <Th noPadding minWidth={100}>
                No. PR
              </Th> */}
              <Th noPadding minWidth={100}>
                Tgl. PO
              </Th>
              <Th noPadding minWidth={100}>
                No. PO
              </Th>
              <Th noPadding minWidth={200}>
                Vendor
              </Th>
              <Th noPadding minWidth={250}>
                Nama Material
              </Th>
              <Th noPadding minWidth={100}>
                Qty. PO
              </Th>
              <Th noPadding minWidth={100}>
                Satuan
              </Th>
              <Th noPadding minWidth={120}>
                Harga Satuan
              </Th>
              <Th noPadding minWidth={100}>
                Diskon
              </Th>
              <Th noPadding minWidth={100}>
                PPN
              </Th>
              <Th noPadding minWidth={120}>
                Sub Total Harga
              </Th>
              <Th noPadding minWidth={100}>
                Pembayaran
              </Th>
              {/* <Th noPadding minWidth={100}>
                Sisa Qty. PO
              </Th> */}
              {/* <Th noPadding minWidth={100}>Tgl. Jatuh Tempo</Th> */}
              {/* <Th noPadding minWidth={100}>
                Alamat Tujuan Pengiriman
              </Th> */}
              <Th noPadding minWidth={100}>
                Penerimaan Barang
              </Th>
              <Th noPadding minWidth={100}>
                Sisa Qty. PO
              </Th>
            </Tr>
          </THead>
          <TBody>
            {data && data?.length > 0 ? (
              data?.map((val, index) => {
                const qty_order = val.qty_order ? parseFloat(val.qty_order) : 0;
                const harga_kesepakatan = val.harga_kesepakatan
                  ? parseFloat(val.harga_kesepakatan)
                  : 0;
                const ppn = val.ppn ? parseFloat(val.ppn) : 0;
                const diskon = val.diskon ? parseFloat(val.diskon) : 0;
                const total = harga_kesepakatan * qty_order;
                const hasil_diskon = total - total * (diskon / 100);
                const hasil_ppn = parseFloat(
                  hasil_diskon + hasil_diskon * (ppn / 100)
                );

                const qtySisaPo =
                  parseFloat(val?.qty_order ?? 0) -
                  parseFloat(val?.qty_diterima ?? 0);

                return (
                  <Tr key={index}>
                    <TdFixed>{index + 1}</TdFixed>
                    {/* <Td width={100}>
                      {val.tgl_purchase_request
                        ? DateConvert(new Date(val.tgl_purchase_request))
                            .defaultDMY
                        : "-"}
                    </Td> */}
                    {/* <Td width={100}>{val.no_purchase_request}</Td> */}
                    <Td>
                      {val.tgl_purchase_order
                        ? DateConvert(new Date(val.tgl_purchase_order))
                            .defaultDMY
                        : "-"}
                    </Td>
                    <Td>{val.no_purchase_order}</Td>
                    <Td>{val.nama_vendor}</Td>
                    <Td>{val.nama_item}</Td>
                    <Td className="text-right">
                      {DecimalConvert(qty_order).getWithComa}
                    </Td>
                    <Td>{val.nama_satuan_beli}</Td>
                    <Td className="text-right">
                      {RupiahConvert(String(harga_kesepakatan)).getWithComa}
                    </Td>
                    <Td className="text-right">{diskon}%</Td>
                    <Td className="text-right">{ppn}%</Td>
                    <Td className="text-right">
                      {RupiahConvert(String(hasil_ppn)).getWithComa}
                    </Td>
                    <Td>{val.pembayaran}</Td>
                    {/* <Td className='text-rght"'>{val.qty_sisa}</Td> */}
                    {/* <Td>{val.tgl_jatuh_tempo ? DateConvert(new Date(val.tgl_jatuh_tempo)).defaultDMY : "-"}</Td> */}
                    {/* <Td>{val.alamat_tujuan_pengiriman}</Td> */}
                    <Td className="text-right">
                      {DecimalConvert(val.qty_diterima).getWithComa}
                    </Td>
                    <Td className="text-right">
                      {DecimalConvert(qtySisaPo).getWithComa}
                    </Td>
                  </Tr>
                );
              })
            ) : (
              <Tr>
                <Td colSpan={18}>
                  <DataStatus text="Tidak ada data" />
                </Td>
              </Tr>
            )}
          </TBody>
        </CRUDLayout.Table>
      </div>
    );
  };

  if (pageLoading) {
    return (
      <div style={{ marginTop: 200 }}>
        <DataStatus loading={true} text="Memuat data ..." />
      </div>
    );
  } else {
    return (
      <CRUDLayout>
        <Card className="px-3">
          {/* Periode */}
          <div className="d-flex flex-wrap justify-content-start align-items-center">
            <span className="mr-2 mt-1 text-">Periode:</span>
            <div className="p-2 pl-0">
              <DatePicker
                label="Tgl. Mulai"
                name="tanggal_start"
                selected={
                  form.tanggal_start ? new Date(form.tanggal_start) : ""
                }
                onChange={(val) => {
                  const value = val.toISOString().slice(0, 10);
                  setForm((prev) => ({ ...prev, tanggal_start: value }));
                }}
              />
            </div>
            <span className="mx-2 mt-1">s.d.</span>
            <div className="p-2 pl-0">
              <DatePicker
                label="Tgl. Selesai"
                name="tanggal_end"
                selected={form.tanggal_end ? new Date(form.tanggal_end) : ""}
                onChange={(val) => {
                  const value = val.toISOString().slice(0, 10);
                  setForm((prev) => ({ ...prev, tanggal_end: value }));
                }}
              />
            </div>
          </div>

          {/* Filter */}
          <div className="d-flex flex-wrap justify-content-start align-items-center">
            <span className="mr-2 mt-1 text-">Filter By:</span>
            <div className="p-2 pl-0" style={{ width: "200px" }}>
              <SelectSearch
                label="Vendor"
                placeholder="Pilih salah satu..."
                option={atribut?.vendor?.map((val) => {
                  return {
                    value: val.id_vendor,
                    label: val.nama_vendor,
                  };
                })}
                onChange={(val) =>
                  setForm((prev) => ({
                    ...prev,
                    nama_vendor: val.value !== null ? val.label : null,
                  }))
                }
              />
            </div>
            <div className="p-2" style={{ width: "200px" }}>
              <SelectSearch
                label="Nama Material"
                placeholder="Pilih salah satu..."
                option={atribut?.material?.map((val) => {
                  return {
                    value: val.id_item_buaso,
                    label: val.nama_item,
                  };
                })}
                onChange={(val) =>
                  setForm((prev) => ({
                    ...prev,
                    nama_item: val.value !== null ? val.label : null,
                  }))
                }
              />
            </div>
            {/* <div className="p-2">
              <DatePicker
                label="Tgl. Jatuh tempo"
                name="tgl_jatuh_tempo"
                placeholderText="Pilih tanggal"
                selected={form.tgl_jatuh_tempo ? new Date(form.tgl_jatuh_tempo) : ""}
                onChange={(val) => {
                  const value = val.toISOString().slice(0, 10);
                  setForm((prev) => ({ ...prev, tgl_jatuh_tempo: value }));
                }}
              />
            </div> */}
            <ActionButton
              style={{ marginTop: 10 }}
              size="sm"
              text="LIHAT DATA"
              onClick={handleFetchReport}
            />
          </div>
        </Card>

        <Alert
          showCloseButton={true}
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setAlertConfig((prev) => ({ ...prev, show: false }))}
        />

        {!isLoading && data.length > 0 && (
          <div className="d-flex justify-content-end align-items-center mx-1 mt-4 mb-1">
            <ExportButton
              variant="outline-danger"
              text="Export PDF"
              onClick={() => handleButtonExport("pdf")}
            />
            <ExportButton onClick={() => handleButtonExport("excel")} />
          </div>
        )}

        {isLoading ? (
          <DataStatus loading={true} text="Memuat data ..." />
        ) : (
          <TableSection />
        )}
      </CRUDLayout>
    );
  }
};

export default LaporanPurchaseOrder;
