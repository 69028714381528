import Services from '../../services';

class PurchaseOrderApi {
  get(params) {
    return Services.get('/close_purchase_order_aset/page', { params });
  }

  getPO(params) {
    return Services.get('/close_purchase_order_aset/available', { params });
  }

  getSingle(params) {
    return Services.get('/close_purchase_order_aset/single_available', {
      params,
    });
  }

  getSingleClose(params) {
    return Services.get('/close_purchase_order_aset/single', {
      params,
    });
  }

  getVendor(params) {
    return Services.get('/purchase_order/list_vendor', { params });
  }

  getDropdownVendor(params) {
    return Services.get('/dropdown/vendor', { params });
  }
  getDropdownItemBuaso() {
    return Services.get('/dropdown/item_buaso');
  }

  getSingleVendor(params) {
    return Services.get('/purchase_order/list_request_vendor', { params });
  }

  getNomor(params) {
    return Services.get('/close_purchase_order_aset/no_baru', { params });
  }

  save(data) {
    return Services.post('/close_purchase_order_aset', data);
  }
}

export default new PurchaseOrderApi();
