export const formInitialValues = (data) => ({
  tgl_purchase_order_aset: data?.tgl_purchase_order_aset,
  no_purchase_order_aset: data?.no_purchase_order_aset,
  alamat_vendor: data?.alamat_vendor,
  nama_vendor: data?.nama_vendor,
  contact_person: data?.contact_person,
  catatan: data?.catatan,
  tgl_close_purchase_order_aset: data?.tgl_close_purchase_order_aset,
  no_close_purchase_order_aset: data?.no_close_purchase_order_aset,
  id_purchase_order_aset: data?.id_purchase_order_aset,
  detail: data?.detail,
  keterangan: data?.keterangan,
});
