import Services from "../../services";

class VendorApi {
  get() {
    return Services.get("/vendor_master");
  }

  getAllData() {
    return Services.get("/vendor_master");
  }

  getPage(page, dataLength, key) {
    return Services.get(`/vendor_master/page/?page=${page}&per_page=${dataLength}&q=${key}`);
  }

  single(id) {
    return Services.get("/vendor_master/single?id_vendor=" + id);
  }

  search(id) {
    return Services.get("/vendor_master/page?q=" + id);
  }

  singleUsername(name) {
    return Services.get("/vendor_master/single?username=" + name);
  }

  getKode() {
    return Services.get("/vendor_master/no_baru");
  }

  getBank() {
    return Services.get("/vendor_master/bank");
  }

  getProvinsi() {
    return Services.get("/vendor_master/provinsi");
  }

  getKabupaten(data) {
    return Services.get("/vendor_master/kabupaten?id_provinsi=" + data);
  }

  getKecamatan(data) {
    return Services.get("/vendor_master/kecamatan?id_kabupaten=" + data);
  }

  getDesa(data) {
    return Services.get("/vendor_master/desa?id_kecamatan=" + data);
  }

  create(value) {
    return Services.post("/vendor_master", value);
  }

  delete(value) {
    return Services.post("/vendor_master/delete", value);
  }

  hide(value) {
    return Services.put("/vendor_master/hide", value);
  }

  show(value) {
    return Services.put("/vendor_master/show", value);
  }

  update(value) {
    return Services.put("/vendor_master", value);
  }
}

export default new VendorApi();
