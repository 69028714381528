// Components
import {
  DatePicker,
  InfoItemVertical,
  Input,
  NumberFormat,
  SelectSearch,
  TextArea,
} from "components";
import { Col, Row } from "react-bootstrap";
import { DateConvert, DecimalConvert, RupiahConvert } from "utilities";

// Form
import { useFormikContext } from "formik";

// API
import { TransferUpahJobMixApi } from "api";

const FormSection = ({ readOnly, buaso, dropdown }) => {
  // Variables
  const { values, setFieldValue, handleChange, errors, touched } =
    useFormikContext();
  const harga_satuan = parseInt(
    Boolean(values.harga_satuan && values.harga_satuan !== "")
      ? values.harga_satuan
      : 0
  );
  const qty_transfer = parseFloat(
    Boolean(values.qty_transfer && values.qty_transfer !== "")
      ? values.qty_transfer
      : 0
  );
  const total_harga = harga_satuan * qty_transfer;

  const getDataNomor = (value) => {
    TransferUpahJobMixApi.no_baru(value).then((res) => {
      setFieldValue("no_transfer_produksi_bahan", res.data.data);
    });
  };

  if (readOnly) {
    return (
      <Row>
        <Col md={6}>
          <InfoItemVertical
            className="text-capitalize"
            label={`Tgl. Transfer Upah Produksi`}
            text={
              values[`tgl_transfer_produksi_${buaso}`]
                ? DateConvert(
                    new Date(values[`tgl_transfer_produksi_${buaso}`])
                  ).detail
                : "-"
            }
          />
        </Col>

        <Col md={6}>
          <InfoItemVertical
            className="text-capitalize"
            label={`No. Transfer Upah Produksi`}
            text={values[`no_transfer_produksi_${buaso}`] ?? "-"}
          />
        </Col>

        <Col md={6}>
          <InfoItemVertical label="Vendor" text={values.nama_vendor ?? "-"} />
        </Col>

        <Col md={6}>
          <InfoItemVertical
            label="Qty. Transfer"
            text={`${DecimalConvert(qty_transfer).getWithComa} ${
              values.kode_satuan ?? ""
            }`}
          />
        </Col>

        <Col md={6}>
          <InfoItemVertical
            label="Harga Satuan"
            text={RupiahConvert(String(harga_satuan)).getWithComa}
          />
        </Col>

        <Col md={6}>
          <InfoItemVertical
            label="Total Harga"
            text={RupiahConvert(String(parseInt(total_harga))).getWithComa}
          />
        </Col>

        <Col md={6}>
          <InfoItemVertical
            label="Keterangan Transfer"
            text={values.keterangan_transfer ?? "-"}
          />
        </Col>
      </Row>
    );
  } else {
    return (
      <Row>
        <Col md={6}>
          <DatePicker
            label="Tgl. Transfer Upah Produksi"
            selected={
              values.tgl_transfer_produksi_upah
                ? new Date(values.tgl_transfer_produksi_upah)
                : ""
            }
            placeholderText="Pilih Tgl. Transfer Upah Produksi"
            onChange={(date) => {
              const tanggal = DateConvert(new Date(date)).default;

              setFieldValue("tgl_transfer_produksi_upah", tanggal);
              getDataNomor(tanggal);
            }}
            error={
              Boolean(errors.tgl_transfer_produksi_upah) &&
              touched.tgl_transfer_produksi_upah
            }
            errorText={
              Boolean(errors.tgl_transfer_produksi_upah) &&
              touched.tgl_transfer_produksi_upah &&
              errors.tgl_transfer_produksi_upah
            }
          />
        </Col>

        <Col md={6}>
          <Input
            label="No. Transfer Upah Produksi"
            placeholder="No. Transfer Upah Produksi"
            value={values.no_transfer_produksi_upah}
            readOnly
          />
        </Col>

        <Col md={8}>
          <SelectSearch
            label="Vendor"
            placeholder="Pilih Vendor"
            defaultValue={dropdown.vendor.find(
              (e) => e.value === values.id_vendor
            )}
            onChange={(val) => setFieldValue("id_vendor", val.value)}
            option={dropdown.vendor}
            error={Boolean(errors.id_vendor) && touched.id_vendor}
            errorText={
              Boolean(errors.id_vendor) && touched.id_vendor && errors.id_vendor
            }
          />
        </Col>

        <Col md={4}>
          {/* <Input
            label="Qty. Transfer"
            type="text"
            name="qty_transfer"
            placeholder="Qty. Transfer"
            value={values.qty_transfer}
            onChange={handleChange}
            error={Boolean(errors.qty_transfer) && touched.qty_transfer}
            errorText={
              Boolean(errors.qty_transfer) &&
              touched.qty_transfer &&
              errors.qty_transfer
            }
          /> */}
          <NumberFormat
            label="Qty. Transfer"
            value={values.qty_transfer}
            defaultValue={values.qty_transfer}
            onChange={(val) => setFieldValue("qty_transfer", val)}
            error={errors.qty_transfer && touched.qty_transfer && true}
            errorText={errors.qty_transfer}
          />
        </Col>

        <Col md={6}>
          <NumberFormat
            label="Harga Satuan"
            value={values.harga_satuan}
            defaultValue={values.harga_satuan}
            onChange={(value) => setFieldValue("harga_satuan", value)}
            error={errors.harga_satuan && touched.harga_satuan && true}
            errorText={errors.harga_satuan}
          />
        </Col>

        <Col md={6}>
          <Input
            label="Total Harga"
            placeholder="Total Harga"
            value={RupiahConvert(String(parseInt(total_harga))).getWithComa}
            readOnly
          />
        </Col>

        <Col md={12}>
          <TextArea
            label="Keterangan Transfer"
            name="keterangan_transfer"
            rows={4}
            placeholder="Keterangan Transfer"
            value={values.keterangan_transfer}
            onChange={handleChange}
            error={
              Boolean(errors.keterangan_transfer) && touched.keterangan_transfer
            }
            errorText={
              Boolean(errors.keterangan_transfer) &&
              touched.keterangan_transfer &&
              errors.keterangan_transfer
            }
          />
        </Col>
      </Row>
    );
  }
};

export default FormSection;
