import Services from 'services';

class MaintenanceRequestApi {
  async getList(params) {
    const fetch = await Services.get('/seleksi_vendor_aset/page', {
      params,
    });
    return fetch.data;
  }

  async getListPermintaanAset(params) {
    const fetch = await Services.get('/seleksi_vendor_aset/permintaan_aset', {
      params,
    });
    return fetch.data;
  }

  async getSinglePermintaanAset(params) {
    const fetch = await Services.get(
      '/seleksi_vendor_aset/single_permintaan_aset',
      {
        params,
      }
    );
    return fetch.data;
  }

  async getSingle(params) {
    const fetch = await Services.get('/seleksi_vendor_aset/single', {
      params,
    });
    return fetch.data;
  }

  async getDropdownVendor(params) {
    const fetch = await Services.get('/dropdown/vendor', {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_vendor,
      value: val.id_vendor,
    }));
  }

  async getDropdownItemChecklist(params) {
    const fetch = await Services.get('/item_checklist', {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_item_checklist,
      value: val.id_item_checklist,
      kode: val.kode_item_checklist,
    }));
  }

  async getDropdownJenisChecklist() {
    return [
      {
        value: '1',
        label: 'Perlengkapan',
      },
      {
        value: '2',
        label: 'Fungsi',
      },
    ];
  }

  async getDropdownKeperluan() {
    return [
      {
        value: 'produksi',
        label: 'Produksi',
      },
      {
        value: 'oprasional',
        label: 'Orpasional',
      },
    ];
  }

  async getDropdownMetodePengadaan() {
    return [
      {
        value: 'produksi',
        label: 'Produksi',
      },
      {
        value: 'pembelian',
        label: 'Pembelian',
      },
    ];
  }

  async getDropdownUnitProduksi() {
    return [
      {
        value: '1',
        label: 'Produksi',
      },
      {
        value: '2',
        label: 'Pembelian',
      },
    ];
  }

  async getDropdownEntitasAset() {
    return [
      {
        value: '1',
        label: 'AJB',
      },
      {
        value: '2',
        label: 'MGP',
      },
    ];
  }

  async getDropdownGrupAset(params) {
    const fetch = await Services.get('/grupaset', {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_grup_aset,
      value: val.id_grup_aset,
    }));
  }

  async getDropdownKategoriAset(params) {
    const fetch = await Services.get('/kategoriaset', {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_kategori_aset,
      value: val.id_kategori_aset,
    }));
  }

  async getDropdownJenisAset(params) {
    const fetch = await Services.get('/jenisaset', {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_jenis_aset,
      value: val.id_jenis_aset,
    }));
  }

  async getDropdownItemAset(params) {
    const fetch = await Services.get('/analisa_item_checklist/item_aset', {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_item_aset,
      value: val.id_item_aset,
      kode: val.kode_item_aset,
    }));
  }

  generateNumber(params) {
    return Services.get('/seleksi_vendor_aset/no_baru', { params });
  }

  create(data) {
    return Services.post('/seleksi_vendor_aset', data);
  }

  update(data) {
    return Services.put('/seleksi_vendor_aset', data);
  }
}

export default new MaintenanceRequestApi();
