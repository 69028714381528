import React from 'react';
import RDatePicker from 'react-datepicker';

const DatePicker = ({ label, error, errorText, dateFormat, ...rest }) => {
  return (
    <div className="mb-2 d-flex flex-column" style={{ paddingTop: '5px' }}>
      <small>{label}</small>
      <RDatePicker
        {...rest}
        onChangeRaw={(e) => e.preventDefault()}
        className={`form-control form-control-sm ${error && 'is-invalid'}`}
        dateFormat={dateFormat ? dateFormat : 'dd/MM/yyy'}
      />
      <small className="text-danger pt-1">{error && errorText}</small>
    </div>
  );
};

export default DatePicker;
