import React, { Fragment, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { Formik, useFormikContext } from 'formik';
import { InfoItemVertical } from 'components2';

import { BackButton, InfoItemHorizontal } from 'components';
import { TablePermintaanAset } from './TablePermintaanAset';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ModalForm } from './ModalForm';
import { DateConvert } from 'utilities';
import {
  formInitialValues,
  formSubmitValueMapper,
  formValidationSchema,
} from '../__SeleksiVendorAsetUtils__';
import { useMutation } from 'react-query';
import SeleksiVendorAsetApi from '../__SeleksiVendorAsetApi__';

export const FormSeleksiVendorAsetContent = ({
  action,
  setAlertConfig,
  refetch,
}) => {
  const { values } = useFormikContext();
  const history = useHistory();

  const [modalConfig, setModalConfig] = useState({
    show: false,
    data: {},
    type: 'add',
  });

  const createSeleksiVendorAset = useMutation((data) =>
    SeleksiVendorAsetApi.create(data)
  );

  const modalSubmitHandler = (value, { setSubmitting, resetForm }) => {
    const finalValue = {
      ...value,
      id_permintaan_aset_detail: modalConfig?.data?.id_permintaan_aset_detail,
    };
    createSeleksiVendorAset
      .mutateAsync(formSubmitValueMapper(finalValue))
      .then(() => {
        refetch();
        setAlertConfig({
          variant: 'primary',
          show: true,
          text: 'Data berhasil disimpan!!!',
        });
        setModalConfig({ ...modalConfig, data: {}, show: false });
        setSubmitting(false);
        resetForm();
      })
      .catch((err) => {
        setAlertConfig({
          variant: 'danger',
          show: true,
          text: 'Data gagal disimpan!!!',
        });
        setModalConfig({ ...modalConfig, data: {}, show: false });
        setSubmitting(false);
        resetForm();
      });
  };

  return (
    <Fragment>
      <div className="d-flex justify-content-between mb-2">
        <small className="font-weight-bold">
          {action === 'create'
            ? 'Tambah Data Seleksi Vendor Aset'
            : action === 'update'
            ? 'Ubah Data Seleksi Vendor Aset'
            : 'Detail Data Seleksi Vendor Aset'}
        </small>

        <BackButton
          size="sm"
          onClick={() => history.push('/transaksi/seleksi-vendor-aset')}
        />
      </div>
      <Card>
        <Card.Body>
          <InfoItemHorizontal
            label="Tgl. Permintaan Aset"
            text={
              values?.tgl_permintaan_aset
                ? DateConvert(new Date(values?.tgl_permintaan_aset)).detail
                : '-'
            }
          />
          <InfoItemHorizontal
            label="No. Permintaan Aset"
            text={values?.no_permintaan_aset ?? '-'}
          />
          <InfoItemHorizontal
            label="Catatan Permintaan"
            text={values?.catatan_permintaan ?? '-'}
          />
        </Card.Body>
      </Card>

      <div className="d-flex justify-content-between mt-3">
        <small className="font-weight-bold">Detail Aset Yang Diminta</small>
      </div>
      <Card>
        <Card.Body>
          <TablePermintaanAset
            dataTable={values?.detail}
            action={action}
            setModalConfig={setModalConfig}
            modalConfig={modalConfig}
          />
        </Card.Body>
      </Card>

      {modalConfig.show && (
        <Formik
          initialValues={formInitialValues()}
          validationSchema={formValidationSchema}
          onSubmit={modalSubmitHandler}
        >
          <ModalForm
            setModalConfig={setModalConfig}
            modalConfig={modalConfig}
            action={action}
          />
        </Formik>
      )}
    </Fragment>
  );
};
