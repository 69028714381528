import { TBody, THead, Table, Td, Th, ThFixed, Tr } from "components";
import { useFormikContext } from "formik";
import React from "react";
import { DecimalConvert } from "utilities";

export const TableSection = () => {
  const { values } = useFormikContext();
  return (
    <div className="mt-3">
      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <ThFixed className="text-nowrap">Kode Item</ThFixed>
            <Th>Item Barang</Th>
            <Th>Proyek</Th>
            <Th>Qty. Order</Th>
            <Th>Satuan Beli</Th>
            <Th>Qty. Penerimaan Barang</Th>
            <Th>Qty. Pakai</Th>
            <Th>Satuan Pakai</Th>
          </Tr>
        </THead>
        <TBody>
          {values?.detail &&
            values?.detail.length > 0 &&
            values?.detail?.map((val, ind) => (
              <Tr>
                <Td>{ind + 1}</Td>
                <Td>{val.kode_item ?? "-"}</Td>
                <Td>{val.nama_item ?? "-"}</Td>
                <Td>{val?.nama_proyek ?? "-"}</Td>
                <Td className="text-right">
                  {DecimalConvert(parseFloat(val?.qty_order ?? 0)).getWithComa}
                </Td>
                <Td>{val.nama_satuan_beli ?? "-"}</Td>
                <Td className="text-right">
                  {
                    DecimalConvert(parseFloat(val?.qty_penerimaan ?? 0))
                      .getWithComa
                  }
                </Td>
                <Td className="text-right">
                  {DecimalConvert(parseFloat(val?.qty_pakai ?? 0)).getWithComa}
                </Td>
                <Td>{val.nama_satuan_pakai ?? "-"}</Td>
              </Tr>
            ))}
        </TBody>
      </Table>
    </div>
  );
};
