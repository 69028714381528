import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Alert,
  CreateButton,
  CRUDLayout,
  DataStatus,
  FilterButton,
  InputSearch,
  Pagination,
  ReadButton,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
} from 'components';
import { TransferSubkonWorkOrderApi } from 'api';
import {
  DateConvert,
  DecimalConvert,
  RupiahConvert,
  TableNumber,
} from 'utilities';
import { ModalFilterPage, ModalFormTransfer } from './components';
import { withDropdownProvider } from './context/DropdownContext';

export const ListTransferSubkonWorkOrder = withDropdownProvider(
  ({ setNavbarTitle }) => {
    const history = useHistory();
    const location = useLocation();
    const title = 'Transfer Subkon Work Order';
    const filter = location.state?.filter ?? {};

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([{}]);
    const [totalPage, setTotalPage] = useState(1);
    const [totalData, setTotalData] = useState('');
    const [modalFilter, setModalFilter] = useState(false);
    const [dataFilter, setDataFilter] = useState({
      active: filter.pre?.active,
      page: filter.pre?.page ?? 1,
      per_page: filter.pre?.per_page ?? 10,
      q: filter.pre?.q ?? '',

      tgl_transfer_work_order_upah_mulai:
        filter.pre?.tgl_transfer_work_order_upah_mulai,
      tgl_transfer_work_order_upah_selesai:
        filter.pre?.tgl_transfer_work_order_upah_selesai,

      tgl_permintaan_work_order_upah_mulai:
        filter.pre?.tgl_permintaan_work_order_upah_mulai,
      tgl_permintaan_work_order_upah_selesai:
        filter.pre?.tgl_permintaan_work_order_upah_selesai,
      id_item_upah: filter.pre?.id_item_upah,
      id_vendor: filter.pre?.id_vendor,
    });
    const [alertConfig, setAlertConfig] = useState({
      show: false,
      variant: 'primary',
      text: '',
    });
    const [modal, setModal] = useState(false);
    const [dataModal, setDataModal] = useState({});
    const [searchTime, setSearchTime] = useState(null);

    const getData = () => {
      setIsLoading(true);

      const filterData = { ...dataFilter };
      delete filterData.active;

      TransferSubkonWorkOrderApi.page(filterData)
        .then((res) => {
          setData(res.data.data);
          setTotalPage(res.data.total_page);
          setTotalData(res.data.data_count);
        })
        .catch(() => {
          setAlertConfig({
            show: true,
            variant: 'danger',
            text: 'Data gagal dimuat!',
          });
        })
        .finally(() => setIsLoading(false));
    };

    const checkAlert = () => {
      const locationState = location.state;

      if (locationState) {
        if (locationState.alert) {
          setAlertConfig({
            show: locationState.alert.show,
            text: locationState.alert.text,
            variant: locationState.alert.variant,
          });
        }
      }
    };

    const onInputSearch = (text) => {
      const key = text.target.value;
      if (searchTime) clearTimeout(searchTime);
      setSearchTime(
        setTimeout(() => {
          setDataFilter({
            ...dataFilter,
            page: '1',
            q: key,
          });
          setAlertConfig({
            show: key ? true : false,
            variant: 'primary',
            text: 'Hasil dari pencarian: ' + key,
          });
        }, 750),
      );
    };

    useEffect(() => {
      setNavbarTitle(title);
      checkAlert();

      // eslint-disable-next-line
    }, [setNavbarTitle]);

    useEffect(() => {
      getData();

      // eslint-disable-next-line
    }, [dataFilter]);

    const toggleModal = () => setModal(!modal);

    const toggleModalFilter = () => setModalFilter(!modalFilter);

    const Table = () => {
      return (
        <>
          <b>List Data {title}</b>
          <CRUDLayout.Table>
            <THead>
              <Tr>
                <ThFixed>No</ThFixed>
                <ThFixed>Aksi</ThFixed>
                <ThFixed>Informasi Transfer Subkon Work Order</ThFixed>
                <ThFixed>Informasi Permintaan Work Order</ThFixed>
                <Th>Item Subkon</Th>
                <ThFixed>Qty. Transfer</ThFixed>
                <Th>Vendor</Th>
                <Th>Harga Satuan</Th>
                <Th>Total Harga</Th>
              </Tr>
            </THead>
            <TBody>
              {data?.map((val, index) => (
                <Tr key={index}>
                  <TdFixed>
                    {TableNumber(dataFilter.page, dataFilter.per_page, index)}
                  </TdFixed>

                  <TdFixed>
                    <ReadButton
                      size="sm"
                      onClick={() => {
                        setDataModal(val);
                        toggleModal();
                      }}
                    />
                  </TdFixed>

                  <Td>
                    <div>
                      {val.tgl_transfer_work_order_subkon
                        ? DateConvert(
                            new Date(val.tgl_transfer_work_order_subkon),
                          ).defaultDMY
                        : '-'}
                    </div>
                    <div>{val.no_transfer_work_order_subkon ?? '-'}</div>
                  </Td>

                  <Td>
                    <div>
                      {val.tgl_permintaan_work_order_subkon
                        ? DateConvert(
                            new Date(val.tgl_permintaan_work_order_subkon),
                          ).defaultDMY
                        : '-'}
                    </div>
                    <div>{val.no_permintaan_work_order_subkon ?? '-'}</div>
                  </Td>

                  <Td>{val.nama_item ?? '-'}</Td>

                  <Td className="text-right">{`${
                    DecimalConvert(val.qty_transfer_work_order_subkon ?? 0)
                      .getWithComa
                  } ${val.kode_satuan ?? ''}`}</Td>

                  <Td>{val.nama_vendor ?? '-'}</Td>

                  <Td className="text-right">
                    {
                      RupiahConvert(
                        parseFloat(val.harga_satuan ?? 0).toString(),
                      ).getWithComa
                    }
                  </Td>

                  <Td className="text-right">
                    {
                      RupiahConvert(
                        Math.round(
                          parseFloat(val.harga_satuan ?? 0) *
                            parseFloat(val.qty_transfer_work_order_subkon ?? 0),
                        ).toString(),
                      ).getWithComa
                    }
                  </Td>
                </Tr>
              ))}
            </TBody>
          </CRUDLayout.Table>

          <Pagination
            dataLength={dataFilter.per_page}
            dataPage={
              totalData <= 10
                ? data.length
                : data.map((_res, index) => {
                    if (index === data.length - 1) {
                      return TableNumber(
                        dataFilter.page,
                        dataFilter.per_page,
                        index,
                      );
                    } else {
                      return null;
                    }
                  })
            }
            dataNumber={data.map((_res, index) => {
              if (index === 0) {
                return TableNumber(dataFilter.page, dataFilter.per_page, index);
              } else {
                return null;
              }
            })}
            dataCount={totalData}
            onDataLengthChange={(e) => {
              setDataFilter({
                ...dataFilter,
                per_page: e.target.value,
                page: 1,
              });
            }}
            currentPage={dataFilter.page}
            totalPage={totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                page: selected + 1,
              })
            }
          />
        </>
      );
    };

    return (
      <CRUDLayout>
        <CRUDLayout.Head>
          <CRUDLayout.HeadSearchSection>
            <div className="d-flex mb-3">
              <InputSearch onChange={onInputSearch} />

              <FilterButton
                active={dataFilter?.active}
                onClick={toggleModalFilter}
              />
            </div>
          </CRUDLayout.HeadSearchSection>

          <CRUDLayout.HeadButtonSection>
            <CreateButton
              onClick={() => {
                history.push(
                  '/transaksi/transfer-subkon-work-order/list-permintaan-work-order',
                  {
                    filter: {
                      ...filter,
                      pre: dataFilter,
                    },
                  },
                );
              }}
            />
          </CRUDLayout.HeadButtonSection>
        </CRUDLayout.Head>

        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() => {
            setAlertConfig({
              ...alertConfig,
              show: false,
            });
            history.replace('/transaksi/transfer-subkon-work-order', {
              registerAlertConfig: undefined,
            });
          }}
        />

        {isLoading ? (
          <DataStatus loading={true} text="Memuat data..." />
        ) : data.length ? (
          <Table />
        ) : (
          <DataStatus text="Tidak ada data" />
        )}

        {modal && (
          <ModalFormTransfer
            data={dataModal}
            title={title}
            toggle={toggleModal}
          />
        )}

        {modalFilter && (
          <ModalFilterPage
            toggle={toggleModalFilter}
            data={dataFilter}
            setData={setDataFilter}
          />
        )}
      </CRUDLayout>
    );
  },
);
