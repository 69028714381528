import Services from '../../../services';

class PurchaseOrderApiAset {
  get(params) {
    return Services.get('/purchase_order_aset/page', { params });
  }

  getSingle(params) {
    return Services.get('/purchase_order_aset/single', { params });
  }

  getSingleSelesiVendor(params) {
    return Services.get('/seleksi_vendor/single', { params });
  }

  getSeleksiVendor(params) {
    return Services.get('/purchase_order_aset/seleksi_vendor', { params });
  }

  getVendor(params) {
    return Services.get('/purchase_order_aset/list_vendor', { params });
  }

  getDropdownVendor(params) {
    return Services.get('/dropdown/vendor', { params });
  }
  getDropdownItemBuaso() {
    return Services.get('/dropdown/item_buaso');
  }

  getSingleVendor(params) {
    return Services.get('/purchase_order_aset/single_seleksi_vendor', {
      params,
    });
  }

  getNomor(params) {
    return Services.get('/purchase_order_aset/no_baru', { params });
  }

  createOrUpdate(data) {
    return Services.post('/purchase_order_aset', data);
  }

  update(data) {
    return Services.put('/purchase_order_aset', data);
  }

  delete(data) {
    return Services.post('/purchase_order_aset/delete', data);
  }
}

export default new PurchaseOrderApiAset();
