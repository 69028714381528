// React
import React, { useState, useEffect } from 'react';

// Form
import { Formik } from 'formik';

// Components
import { DatePicker, FilterModal, SelectSearch } from 'components';
import { DateConvert } from 'utilities';

const ModalFilter = ({ toggle, data, setData, dropdown }) => {
  // FORM VALUES
  const formInitialValues = {
    tgl_seleksi_vendor_aset: data.tgl_seleksi_vendor_aset,
    id_item: data.id_item,
    id_vendor: data.id_vendor,
  };

  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    setData({
      ...data,
      ...values,
      active: checkActive,
      page: 1,
    });

    toggle();
  };

  // HANDLE CHANGE
  const onResetButtonClick = (setValues) => {
    setValues({
      tgl_seleksi_vendor_aset: undefined,
      id_item: undefined,
      id_vendor: undefined,
      status_approval: undefined,
    });
  };

  const onTanggalChange = (dates, values, setValues, value_awal) => {
    const date = dates ? DateConvert(dates).default : null;

    setValues({
      ...values,
      [value_awal]: date,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}
    >
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          isToggle
          toggleModal={toggle}
          onResetButtonClick={() => onResetButtonClick(setValues)}
          onFilterButtonClick={handleSubmit}
        >
          {console.log('tres', values)}
          <DatePicker
            label={<div className="text-capitalize">Tgl. Seleksi Vendor</div>}
            placeholderText={`Pilih Tgl. Seleksi Vendor Produksi`}
            selected={
              values?.tgl_seleksi_vendor_aset
                ? new Date(values?.tgl_seleksi_vendor_aset)
                : ''
            }
            onChange={(dates) =>
              onTanggalChange(
                dates,
                values,
                setValues,
                `tgl_seleksi_vendor_aset`
              )
            }
          />

          {/* <SelectSearch
            key="1"
            label="Item Barang"
            placeholder="Pilih Item Barang"
            defaultValue={
              values?.id_item &&
              dropdown?.itemBuaso?.find((val) => val?.value === values?.id_item)
            }
            option={dropdown?.itemBuaso}
            onChange={(val) => setFieldValue("id_item", val.value)}
          /> */}

          <SelectSearch
            key="2"
            label="Vendor"
            placeholder="Pilih Vendor"
            defaultValue={
              values?.id_vendor &&
              dropdown?.vendor?.find((val) => val?.value === values?.id_vendor)
            }
            option={dropdown?.vendor}
            onChange={(val) => setFieldValue('id_vendor', val.value)}
          />
          {/* <SelectSearch
            key="2"
            label="Status Approval"
            placeholder="Pilih Status Approval"
            defaultValue={
              values.status_approval &&
              dataApproval?.find((val) => val.value === values.status_approval)
                .label
            }
            option={dataApproval}
            onChange={(val) => setFieldValue("status_approval", val.value)}
          /> */}
        </FilterModal>
      )}
    </Formik>
  );
};
export default ModalFilter;
