import Services from "services";

class PermintaanUpahProduksiApi {
  // transfer
  jobmix_transfer_upah(value) {
    return Services.get("/jobmix_transfer_upah", { params: {...value} });
  }
  page(value) {
    return Services.get("/jobmix_transfer_upah/page", { params: {...value} });
  }

  // permintaan
  permintaan_page(value) {
    return Services.get("/jobmix_transfer_upah/page_permintaan", { params: {...value} });
  }
  single_permintaan(value) {
    return Services.get("/jobmix_transfer_upah/single_permintaan", { params: {...value} });
  }
  create(value) {
    return Services.post("/jobmix_transfer_upah", value);
  }

  // atribut
  dropdown(value) {
    return Services.get("/jobmix_transfer_upah/dropdown", { params: {...value} });
  }
  no_baru(value) {
    return Services.get("/jobmix_transfer_upah/no_baru", { params: {...value} });
  }
}

export default new PermintaanUpahProduksiApi();
