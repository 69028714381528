// React
import React from 'react';

// Form
import { useFormikContext } from 'formik';

// Components
import { DatePicker, FilterModal } from 'components';
import { Select } from 'components2';

// API
import { useQuery } from 'react-query';
import { DateConvert } from 'utilities';
import TransferUpahProduksiAsetApi from '../TransferUpahProduksiAsetApi';

export const ModalFilter = ({ toggle }) => {
  const { values, handleSubmit, setValues } = useFormikContext();

  const { data: dropdownVendor, isFetching: loadingDropdownVendor } = useQuery(
    ['vendor', 'dropdown'],
    () => TransferUpahProduksiAsetApi.dropdownVendor()
  );

  const { data: dropdownItem, isFetching: loadingDropdownItem } = useQuery(
    ['item', 'dropdown'],
    () => TransferUpahProduksiAsetApi.dropdownItem()
  );

  // HANDLE CHANGE
  const onResetButtonClick = (setValues) => {
    const myPromise = new Promise((resolve) => {
      setValues({
        tgl_transfer_produksi_aset_upah_mulai: undefined,
        tgl_transfer_produksi_aset_upah_selesai: undefined,
        tgl_permintaan_produksi_aset_upah_mulai: undefined,
        tgl_permintaan_produksi_aset_upah_selesai: undefined,
        id_vendor: undefined,
        id_item_upah: undefined,
        active: false,
      });

      setTimeout(() => resolve(), 300);
    });

    myPromise.then(() => {});
  };

  const onTanggalChange = (
    dates,
    values,
    setValues,
    value_awal,
    value_akhir
  ) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      [value_awal]: startDate,
      [value_akhir]: endDate,
    });
  };

  return (
    <FilterModal
      isToggle
      toggleModal={toggle}
      onResetButtonClick={() => onResetButtonClick(setValues)}
      onFilterButtonClick={handleSubmit}
    >
      <DatePicker
        selectsRange
        label="Tgl. Transfer Upah Produksi Aset"
        placeholderText="Pilih tanggal"
        startDate={
          values?.tgl_transfer_produksi_aset_upah_mulai
            ? new Date(values?.tgl_transfer_produksi_aset_upah_mulai)
            : ''
        }
        endDate={
          values?.tgl_transfer_produksi_aset_upah_selesai
            ? new Date(values?.tgl_transfer_produksi_aset_upah_selesai)
            : ''
        }
        onChange={(dates) =>
          onTanggalChange(
            dates,
            values,
            setValues,
            'tgl_transfer_produksi_aset_upah_mulai',
            'tgl_transfer_produksi_aset_upah_selesai'
          )
        }
        monthsShown={2}
      />

      <DatePicker
        selectsRange
        label="Tgl. Permintaan Produksi Aset"
        placeholderText="Pilih tanggal"
        startDate={
          values?.tgl_permintaan_produksi_aset_upah_mulai
            ? new Date(values?.tgl_permintaan_produksi_aset_upah_mulai)
            : ''
        }
        endDate={
          values?.tgl_permintaan_produksi_aset_upah_selesai
            ? new Date(values?.tgl_permintaan_produksi_aset_upah_selesai)
            : ''
        }
        onChange={(dates) =>
          onTanggalChange(
            dates,
            values,
            setValues,
            'tgl_permintaan_produksi_aset_upah_mulai',
            'tgl_permintaan_produksi_aset_upah_selesai'
          )
        }
        monthsShown={2}
      />

      <Select
        label="Item Upah"
        placeholder="Pilih item upah"
        name="id_item_upah"
        options={dropdownItem ?? []}
        loading={loadingDropdownItem}
        onChange={async (val) =>
          setValues({
            ...values,
            id_item_upah: val.value,
          })
        }
        defaultValue={dropdownItem?.find(
          (val) => val.value === values.id_item_upah
        )}
      />

      <Select
        label="Vendor"
        placeholder="Pilih vendor"
        name="id_vendor"
        options={dropdownVendor ?? []}
        loading={loadingDropdownVendor}
        onChange={async (val) =>
          setValues({
            ...values,
            id_vendor: val.value,
          })
        }
        defaultValue={dropdownVendor?.find(
          (val) => val.value === values.id_vendor
        )}
      />
    </FilterModal>
  );
};
