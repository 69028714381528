import { useEffect, useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { IoAddOutline } from 'react-icons/io5';
import { useHistory, useLocation } from 'react-router-dom';
import {
  ActionButton,
  Alert,
  BackButton,
  CRUDLayout,
  DataStatus,
  FilterButton,
  InputSearch,
  Pagination,
  ReadButton,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
} from 'components';
import { TransferUpahWorkOrderApi } from 'api';
import { DateConvert, DecimalConvert, TableNumber } from 'utilities';
import { ModalFilterPreform, ModalDetailPreform } from './components';
import { withDropdownProvider } from './context/DropdownContext';

export const ListPermintaanUpahWorkOrder = withDropdownProvider(
  ({ setNavbarTitle }) => {
    const history = useHistory();
    const location = useLocation();
    const title = 'Transfer Upah Work Order';
    const filter = location.state?.filter ?? {};

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([{}]);
    const [totalPage, setTotalPage] = useState(1);
    const [totalData, setTotalData] = useState('');
    const [modalFilter, setModalFilter] = useState(false);
    const [dataFilter, setDataFilter] = useState({
      active: filter.post?.active,
      page: filter.post?.page ?? 1,
      per_page: filter.post?.per_page ?? 10,
      q: filter.post?.q ?? '',

      tgl_work_order_mulai: filter.post?.tgl_work_order_mulai,
      tgl_work_order_selesai: filter.post?.tgl_work_order_selesai,

      id_item_upah: filter.post?.id_item_upah,
      id_item_aset: filter.post?.id_item_aset,
    });
    const [alertConfig, setAlertConfig] = useState({
      show: false,
      variant: 'primary',
      text: '',
    });
    const [collapsedIndex, setCollapsedIndex] = useState(-1);
    const [modal, setModal] = useState(false);
    const [idWorkOrder, setIdWorkOrder] = useState(null);

    // request data dari server
    const getData = () => {
      setIsLoading(true);

      const filterData = { ...dataFilter };
      delete filterData.active;

      TransferUpahWorkOrderApi.preformPage(filterData)
        .then((res) => {
          setData(res.data.data);
          setTotalPage(res.data.total_page);
          setTotalData(res.data.data_count);
        })
        .catch(() => {
          setAlertConfig({
            show: true,
            variant: 'danger',
            text: 'Data gagal dimuat!',
          });
        })
        .finally(() => setIsLoading(false));
    };

    const onInputSearchChange = (e) => {
      const key = e.target.value;

      setDataFilter({
        ...dataFilter,
        page: 1,
        q: key,
      });
      setAlertConfig({
        show: key ? true : false,
        variant: 'primary',
        text: 'Hasil dari pencarian: ' + key,
      });
    };

    useEffect(() => {
      setNavbarTitle(title);

      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      setCollapsedIndex(-1);
      getData();

      // eslint-disable-next-line
    }, [dataFilter]);

    const toggleModalFilter = () => setModalFilter(!modalFilter);

    const toggleModal = () => setModal(!modal);

    const Table = () => (
      <>
        <b>List Data Permintaan Work Order Yang Siap Ditransfer </b>
        <CRUDLayout.Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Informasi Work Order</ThFixed>
              <Th>Item Aset</Th>
              <Th>Item Upah Yang Diminta</Th>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data?.map((val, index) => (
              <Tr key={index}>
                <TdFixed>
                  {TableNumber(dataFilter.page, dataFilter.per_page, index)}
                </TdFixed>
                <Td>
                  <div>
                    {val.tgl_work_order
                      ? DateConvert(new Date(val.tgl_work_order)).defaultDMY
                      : '-'}
                  </div>
                  <div>{val.no_work_order ?? '-'}</div>
                </Td>
                <Td>{val.nama_item_aset ?? '-'}</Td>
                <Td>
                  {val?.permintaan?.length === 0 ? (
                    '-'
                  ) : (
                    <>
                      <ul className="pl-3 m-0">
                        {val?.permintaan
                          ?.filter((_val, indexPermintaan) => {
                            if (index === collapsedIndex) {
                              return indexPermintaan <= val?.permintaan?.length;
                            } else {
                              return indexPermintaan <= 2;
                            }
                          })
                          .map((val, index) => (
                            <li key={index}>
                              {val.nama_item ?? '-'}{' '}
                              {`${
                                DecimalConvert(
                                  val.qty_permintaan_work_order_upah ?? 0,
                                ).getWithComa
                              } ${val.nama_satuan ?? ''}`}
                            </li>
                          ))}
                      </ul>
                      {val?.permintaan?.length > 3 && index !== collapsedIndex && (
                        <Button
                          variant="link"
                          className="pt-0"
                          onClick={() => setCollapsedIndex(index)}
                        >
                          Selengkapnya...
                        </Button>
                      )}
                    </>
                  )}
                </Td>
                <TdFixed>
                  <ButtonGroup>
                    <ReadButton
                      noMargin
                      className="my-1"
                      onClick={() => {
                        setIdWorkOrder(val.id_work_order);
                        toggleModal();
                      }}
                    />

                    <ActionButton
                      text={<IoAddOutline size={12} />}
                      className="my-1"
                      size="sm"
                      onClick={() => {
                        history.push(
                          `/transaksi/transfer-upah-work-order/tambah/${val.id_work_order}`,
                          {
                            filter: {
                              ...filter,
                              post: dataFilter,
                            },
                          },
                        );
                      }}
                    />
                  </ButtonGroup>
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </CRUDLayout.Table>
        <Pagination
          dataLength={dataFilter.per_page}
          dataPage={
            totalData <= 10
              ? data?.length
              : data.map((_res, index) => {
                  if (index === data?.length - 1) {
                    return TableNumber(
                      dataFilter.page,
                      dataFilter.per_page,
                      index,
                    );
                  } else {
                    return null;
                  }
                })
          }
          dataNumber={data.map((_res, index) => {
            if (index === 0) {
              return TableNumber(dataFilter.page, dataFilter.per_page, index);
            } else {
              return null;
            }
          })}
          dataCount={totalData}
          onDataLengthChange={(e) => {
            setDataFilter({
              ...dataFilter,
              per_page: e.target.value,
              page: 1,
            });
          }}
          currentPage={dataFilter.page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) =>
            setDataFilter({
              ...dataFilter,
              page: selected + 1,
            })
          }
        />
      </>
    );

    return (
      <CRUDLayout>
        <CRUDLayout.Head>
          <CRUDLayout.HeadSearchSection>
            <div className="d-flex mb-3">
              <InputSearch
                value={dataFilter?.q}
                onChange={onInputSearchChange}
              />

              <FilterButton
                active={dataFilter?.active}
                onClick={toggleModalFilter}
              />
            </div>
          </CRUDLayout.HeadSearchSection>
          <CRUDLayout.HeadButtonSection>
            <BackButton
              onClick={() => {
                history.replace('/transaksi/transfer-upah-work-order', {
                  filter: {
                    ...filter,
                    post: dataFilter,
                  },
                });
              }}
            />
          </CRUDLayout.HeadButtonSection>
        </CRUDLayout.Head>

        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() => {
            setAlertConfig({
              ...alertConfig,
              show: false,
            });
          }}
        />

        {isLoading ? (
          <DataStatus loading={true} text="Memuat data..." />
        ) : data.length ? (
          <Table />
        ) : (
          <DataStatus text="Tidak ada data" />
        )}

        {modalFilter && (
          <ModalFilterPreform
            toggle={toggleModalFilter}
            data={dataFilter}
            setData={setDataFilter}
          />
        )}

        {modal && (
          <ModalDetailPreform
            show={modal}
            toggle={toggleModal}
            idWorkOrder={idWorkOrder}
          />
        )}
      </CRUDLayout>
    );
  },
);
