// React
import React, { useState, useEffect } from "react"

// Components
import { Col, Row } from "react-bootstrap"
import {
  CRUDLayout, InputSearch, DataStatus, Alert, Select
} from "../../../components"

// View Components
import { TabItem } from "./components"

const HargaPerkiraanSendiri = ({ setNavbarTitle }) => {
  const title = "Harga Perkiraan Sendiri"
  const buaso = [
    {
      label: "Semua Data",
      value: ""
    },
    {
      label: "Bahan",
      value: "1"
    },
    {
      label: "Upah",
      value: "2"
    },
    {
      label: "Alat dan mesin",
      value: "3"
    },
    {
      label: "Subkon",
      value: "4"
    }
  ]

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(false)

  // STATE DATA SATUAN
  // const [key, setKey] = useState("")
  const [filter, setFilter] = useState("")
  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false)
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  })
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [searchTime, setSearchTime] = useState(null);

  const onInputSearchChange = (e) => {
    const value = e.target.value
    if(searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
          setSearchConfig({
            ...searchConfig,
            key: e.target.value
          })
          setAlertConfig({
            text: value,
            variant: 'primary'
          })
          setShowAlert(value ? true : false)
      }, 750)
    )
  }

  
  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title) // SET JUDUL NAVBAR

    return () => {
      setIsLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNavbarTitle])

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row>
            <Col md="8">
              <InputSearch
                onChange={onInputSearchChange}
                // onSubmit={(e) => e.preventDefault()}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          <Select onChange={e => setFilter(e.target.value)}>
            <option value="" hidden>Pilih Buaso</option>
            {buaso.map((val, index) => <option key={index} value={val.value}>{val.label}</option>)}
          </Select>
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : (
        <>
          <b>List Data Harga Perkiraan Sendiri</b>

          <TabItem q={searchConfig.key} filter={filter} />
        </>
      )}
    </CRUDLayout>
  )
}

export default HargaPerkiraanSendiri
