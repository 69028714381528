import Services from '../../../services';

class PurchaseOrderApi {
  get(params) {
    return Services.get('/purchase_order/page', { params });
  }

  getSingle(params) {
    return Services.get('/purchase_order/single', { params });
  }

  getSingleSelesiVendor(params) {
    return Services.get('/seleksi_vendor/single', { params });
  }

  getVendor(params) {
    return Services.get('/purchase_order/list_vendor', { params });
  }

  getDropdownVendor(params) {
    return Services.get('/dropdown/vendor', { params });
  }
  getDropdownItemBuaso() {
    return Services.get('/dropdown/item_buaso');
  }

  getSingleVendor(params) {
    return Services.get('/purchase_order/list_request_vendor', { params });
  }

  getNomor(params) {
    return Services.get('/purchase_order/no_baru', { params });
  }

  createOrUpdate(data) {
    return Services.post('/purchase_order', data);
  }

  delete(data) {
    return Services.post('/purchase_order/delete', data);
  }
}

export default new PurchaseOrderApi();
