import Services from 'services';

class DashboardApi {
  async getTotalVendor(params) {
    const data = await Services.get('/dashboard/total_vendor', { params });
    return data.data;
  }

  async getVendorResmi(params) {
    const data = await Services.get('/dashboard/vendor_resmi', { params });
    return data.data;
  }

  async getCalonVendor(params) {
    const data = await Services.get('/dashboard/calon_vendor', { params });
    return data.data;
  }

  async getVendorTerbaik(params) {
    const data = await Services.get('/dashboard/vendor_terbaik', { params });
    return data.data;
  }

  async getTotalPembelian(params) {
    const data = await Services.get('/dashboard/total_pembelian', { params });
    return data.data;
  }

  async getTotalPembelianTahun(params) {
    const data = await Services.get('/dashboard/po_tahunan', { params });
    return data.data;
  }

  async getTotalPembelianHari(params) {
    const data = await Services.get('/dashboard/po_harian', { params });
    return data.data;
  }

  async getPembelian(params) {
    const data = await Services.get('/dashboard/list_po', { params });
    return data.data;
  }
}

export default new DashboardApi();
