import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { FilterModal, DatePicker, SelectSearch } from 'components';
import { DateConvert } from 'utilities';
import Axios from 'axios';
import { ClosePurchaseOrderApi } from 'api';

const ModalFilter = ({ show, setShow, data, setData }) => {
  // STATE DATA
  const [dataVendor, setDataVendor] = useState([
    { label: 'Semua', value: undefined },
  ]);

  const [loading, setLoading] = useState(true);
  // FORM VALUES
  const formInitialValues = {
    tgl_purchase_order_aset_mulai: data?.filter?.tgl_purchase_order_aset_mulai,
    tgl_purchase_order_aset_selesai:
      data?.filter?.tgl_purchase_order_aset_selesai,
    tgl_close_purchase_order_aset_mulai:
      data?.filter?.tgl_close_purchase_order_aset_mulai,
    tgl_close_purchase_order_aset_selesai:
      data?.filter?.tgl_close_purchase_order_aset_selesai,
    id_vendor: data?.filter?.id_vendor,
  };
  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setData({
        filter: {
          ...values,
          active: true,
        },
        pagination: {
          ...data.pagination,
          page: 1,
        },
      });
    } else {
      setData({
        ...data,
        filter: {
          ...values,
          active: false,
        },
      });
    }

    setShow(false);
  };
  const onTanggalCloseSalesOrderChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_purchase_order_aset_mulai: startDate,
      tgl_purchase_order_aset_selesai: endDate,
    });
  };
  const onTanggalSalesOrderChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_close_purchase_order_aset_mulai: startDate,
      tgl_close_purchase_order_aset_selesai: endDate,
    });
  };

  // REQUSET DATA SERVER
  const getDataDropdown = () => {
    Axios.all([ClosePurchaseOrderApi.getDropdownVendor()])
      .then(
        Axios.spread((vendor) => {
          const mapDataCustomer = vendor?.data?.data
            ? vendor.data.data.map((val) => ({
                label: val?.nama_vendor,
                value: val?.id_vendor,
              }))
            : [];

          setDataVendor([...dataVendor, ...mapDataCustomer]);
        })
      )
      .finally(() => setLoading(false));
  };

  const onResetButtonClick = (values, setValues) => {
    setValues({
      tgl_purchase_order_aset_mulai: undefined,
      tgl_purchase_order_aset_selesai: undefined,
      tgl_close_purchase_order_aset_mulai: undefined,
      tgl_close_purchase_order_aset_selesai: undefined,
      id_vendor: undefined,
    });
  };

  useEffect(() => {
    getDataDropdown();

    return () => {};
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}
    >
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(values, setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <DatePicker
            selectsRange
            label="Tgl. Purchase Order Aset"
            placeholderText="DD/MM/YYYY - DD/MM/YYYY"
            startDate={
              values.tgl_purchase_order_aset_mulai
                ? new Date(values.tgl_purchase_order_aset_mulai)
                : ''
            }
            endDate={
              values.tgl_purchase_order_aset_selesai
                ? new Date(values.tgl_purchase_order_aset_selesai)
                : ''
            }
            onChange={(dates) =>
              onTanggalCloseSalesOrderChange(dates, values, setValues)
            }
            monthsShown={2}
          />
          <DatePicker
            selectsRange
            label="Tgl. Close Purchase Order Aset"
            placeholderText="DD/MM/YYYY - DD/MM/YYYY"
            startDate={
              values.tgl_close_purchase_order_aset_mulai
                ? new Date(values.tgl_close_purchase_order_aset_mulai)
                : ''
            }
            endDate={
              values.tgl_close_purchase_order_aset_selesai
                ? new Date(values.tgl_close_purchase_order_aset_selesai)
                : ''
            }
            onChange={(dates) =>
              onTanggalSalesOrderChange(dates, values, setValues)
            }
            monthsShown={2}
          />

          <SelectSearch
            key={values.id_vendor}
            label="Vendor"
            placeholder="Pilih Vendor"
            defaultValue={dataVendor.find(
              (val) => val.value === values.id_vendor
            )}
            option={dataVendor}
            onChange={(val) => setFieldValue('id_vendor', val.value)}
            loading={loading}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
export default ModalFilter;
