import { Nav, Tab, Card } from 'react-bootstrap';
import {
  Table,
  THead,
  Tr,
  ThFixed,
  Th,
  TBody,
  Td,
  TdFixed,
  DataStatus,
} from 'components';
import { RupiahConvert, DecimalConvert } from 'utilities';

export const TabRincianBiaya = ({ data }) => {
  return (
    <>
      <div className="font-weight-bold" style={{ fontSize: '14px' }}>
        Rincian Biaya
      </div>

      <Card className="mb-3">
        <Tab.Container defaultActiveKey="bahan">
          {/* Tab Navigation */}
          <Card.Header>
            <Nav variant="tabs" defaultActiveKey="bahan">
              <NavTab eventKey="bahan" title="Bahan" />
              <NavTab eventKey="upah" title="Upah" />
              <NavTab eventKey="alat" title="Alat Mesin" />
              <NavTab eventKey="subkon" title="Subkon" />
              <NavTab eventKey="overhead" title="Overhead" />
            </Nav>
          </Card.Header>

          {/* Tab Content */}
          <Card.Body className="p-3">
            <Tab.Content>
              <TabPane
                eventKey="bahan"
                component={
                  <TableRincianBiaya buaso="bahan" data={data?.bahan} />
                }
              />
              <TabPane
                eventKey="upah"
                component={<TableRincianBiaya buaso="upah" data={data?.upah} />}
              />
              <TabPane
                eventKey="alat"
                component={
                  <TableRincianBiaya
                    buaso="alat_mesin"
                    data={data?.alat_mesin}
                  />
                }
              />
              <TabPane
                eventKey="subkon"
                component={
                  <TableRincianBiaya buaso="subkon" data={data?.subkon} />
                }
              />
              <TabPane
                eventKey="overhead"
                component={
                  <TableRincianBiaya buaso="overhead" data={data?.overhead} />
                }
              />
            </Tab.Content>
          </Card.Body>
        </Tab.Container>
      </Card>
    </>
  );
};

export const TableRincianBiaya = ({ buaso, data }) => {
  const title = {
    bahan: 'Bahan',
    upah: 'Upah',
    alat_mesin: 'Alat Mesin',
    subkon: 'Subkon',
    overhead: 'Overhead',
  };

  return (
    <>
      <div className="font-weight-bold" style={{ fontSize: '14px' }}>
        List Data {title[buaso]}
      </div>

      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <Th>Kode Item</Th>
            <Th>Item Bahan</Th>
            <Th>Harga Satuan</Th>
            <Th>Qty</Th>
            <Th>Satuan</Th>
            <Th>Subtotal</Th>
          </Tr>
        </THead>

        <TBody>
          {data?.length > 0 ? (
            data?.map((val, index) => (
              <Tr key={index}>
                <TdFixed>{index + 1}</TdFixed>
                <Td>{val.kode_item ?? val.kode_item_aset ?? '-'}</Td>
                <Td>{val.nama_item ?? val.nama_item_aset ?? '-'}</Td>
                <Td className="text-right">
                  {
                    RupiahConvert(String(parseFloat(val.harga_satuan ?? 0)))
                      .getWithComa
                  }
                </Td>
                <Td className="text-right">
                  {
                    DecimalConvert(val?.[`qty_work_order_${buaso}`] ?? 0)
                      .getWithComa
                  }
                </Td>
                <Td>{val.nama_satuan ?? '-'}</Td>
                <Td className="text-right">
                  {
                    RupiahConvert(
                      String(
                        Math.round(
                          parseFloat(val?.harga_satuan ?? 0) *
                            parseFloat(val?.[`qty_work_order_${buaso}`] ?? 0)
                        )
                      )
                    ).getWithComa
                  }
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={7}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
    </>
  );
};

const NavTab = ({ eventKey, title }) => (
  <Nav.Item>
    <Nav.Link eventKey={eventKey}>{title}</Nav.Link>
  </Nav.Item>
);

const TabPane = ({ eventKey, component }) => (
  <Tab.Pane eventKey={eventKey}>{component}</Tab.Pane>
);
