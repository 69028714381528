import React, { useState, useEffect } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { debounce } from 'lodash';
import {
  CRUDLayout,
  Table,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  Pagination,
  Alert,
  ApprovalStatusButton,
} from 'components';
import {
  InputSearch,
  DataStatus,
  ButtonCreate,
  ButtonUpdate,
  ButtonFilter,
  ButtonDetail,
  Button,
} from 'components2';
import { tableNumber } from 'utilities2';
import { ModalFilter } from './__SeleksiVendorAsetComps__';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import { Formik } from 'formik';
import { DateConvert, RupiahConvert } from 'utilities';
import {
  formInitialValues,
  formSubmitValueMapper,
  formValidationSchema,
  getStatusApprovalLabel,
  mappingDetailData,
} from './__SeleksiVendorAsetUtils__';
import { ModalForm } from './__SeleksiVendorAsetComps__/ModalForm';
import SeleksiVendorAsetApi from './__SeleksiVendorAsetApi__';

export const SeleksiVendorAsetList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { state } = useLocation();

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    text: '',
    variant: 'primary',
  });

  const [modalConfig, setModalConfig] = useState({
    show: false,
    data: {},
    id: undefined,
    type: 'detail',
  });

  const [filter, setFilter] = useState({
    q: '',
    page: 1,
    per_page: 10,
    tgl_seleksi_vendor_aset_mulai: undefined,
    tgl_seleksi_vendor_aset_selesai: undefined,
    tgl_permintaan_aset_start: undefined,
    tgl_permintaan_aset_end: undefined,
    id_vendor: undefined,
    status_approval: undefined,
    active: false,
  });

  const [modalFilter, setModalFilter] = useState(false);

  const getSeleksiVendorAset = useQuery(
    ['analisa_checklist', 'list', filter],
    () => SeleksiVendorAsetApi.getList(filter)
  );

  const { data: detailSeleksiVendor, isFetching: loadingDetailSeleksivendor } =
    useQuery(
      ['SeleksiVendorAset', 'single', modalConfig?.id],
      () =>
        SeleksiVendorAsetApi.getSingle({
          id_seleksi_vendor_aset: modalConfig?.id,
        }).then((val) =>
          setModalConfig({
            ...modalConfig,
            show: true,
            data: formInitialValues(val?.data),
          })
        ),
      { enabled: !!modalConfig?.id }
    );

  const searchHandler = (e) => {
    const searchKey = e.target.value ? e.target.value : undefined;

    setFilter({
      ...filter,
      q: searchKey,
    });
    setAlertConfig({
      show: searchKey ? true : false,
      variant: 'primary',
      text: 'Hasil dari pencarian: ' + searchKey,
    });
  };

  const onFilterButtonClickkHandler = () => setModalFilter(!modalFilter);

  const onPaginationChange = ({ selected }) =>
    setFilter({ ...filter, page: selected + 1 });

  const onPaginationDataLengthChange = (e) =>
    setFilter({ ...filter, page: 1, per_page: e.target.value });

  const checkAlert = () => {
    if (state) {
      if (state.alert) {
        setAlertConfig({
          show: state?.alert?.show,
          text: state?.alert?.text,
          variant: state?.alert?.variant,
        });
      }
    }
  };

  // FORM VALUES FILTER
  const formInitialValuesFilter = {
    tgl_seleksi_vendor_aset_mulai: filter?.tgl_seleksi_vendor_aset_mulai,
    tgl_seleksi_vendor_aset_selesai: filter?.tgl_seleksi_vendor_aset_selesai,
    tgl_permintaan_aset_start: filter?.tgl_permintaan_aset_start,
    tgl_permintaan_aset_end: filter?.tgl_permintaan_aset_end,
    id_vendor: filter?.id_vendor,
    status_approval: filter?.status_approval,
  };

  const formSubmitHandlerFilter = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    setFilter({
      ...filter,
      ...values,
      active: checkActive,
      page: 1,
    });
    onFilterButtonClickkHandler();
  };

  const updateSeleksiVendorAset = useMutation((data) =>
    SeleksiVendorAsetApi.update(data)
  );

  const modalSubmitHandler = (value, { setSubmitting, resetForm }) => {
    updateSeleksiVendorAset
      .mutateAsync(formSubmitValueMapper(value))
      .then(() => {
        getSeleksiVendorAset.refetch();
        setAlertConfig({
          variant: 'primary',
          show: true,
          text: 'Data berhasil diubah!!!',
        });
        setModalConfig({
          ...modalConfig,
          data: {},
          show: false,
          id: undefined,
        });
        setSubmitting(false);
        resetForm();
      })
      .catch((err) => {
        setAlertConfig({
          variant: 'danger',
          show: true,
          text: 'Data gagal diubah!!!',
        });
        setModalConfig({
          ...modalConfig,
          data: {},
          show: false,
          id: undefined,
        });
        setSubmitting(false);
        resetForm();
      });
  };

  useEffect(() => {
    setNavbarTitle('Seleksi Vendor Aset');
    checkAlert();
  }, []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={debounce(searchHandler, 700)}
              className="mr-2"
            />
            <ButtonFilter
              size="sm"
              className="text-nowrap"
              onClick={onFilterButtonClickkHandler}
              active={filter?.active}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <ButtonCreate
            size="md"
            icon
            onClick={() => history.push('seleksi-vendor-aset/permintaan-aset')}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {getSeleksiVendorAset.isLoading || getSeleksiVendorAset.isError ? (
        <DataStatus
          loading={getSeleksiVendorAset.isLoading}
          text={
            getSeleksiVendorAset.isLoading
              ? 'Memuat . . .'
              : 'Data gagal dimuat'
          }
        />
      ) : (
        <>
          <Alert
            show={alertConfig?.show}
            text={alertConfig?.text}
            variant={alertConfig?.variant}
            showCloseButton
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          <div className="mt-2">
            <small className="font-weight-bold">
              List Data Seleksi Vendor Aset
            </small>
          </div>
          <Table>
            <THead>
              <Tr>
                <ThFixed>No.</ThFixed>
                <ThFixed>Aksi</ThFixed>
                <ThFixed>Informasi Seleksi Vendor Aset</ThFixed>
                <ThFixed>Informasi Permintaan Aset</ThFixed>
                <Th>Vendor</Th>
                <Th>Item Aset Yang Diminta</Th>
                <Th>Harga Kesepakatan</Th>
                <ThFixed>Status Approval</ThFixed>
              </Tr>
            </THead>
            <TBody>
              {getSeleksiVendorAset?.data?.data?.length > 0 ? (
                getSeleksiVendorAset?.data?.data?.map((e, index) => (
                  <Tr key={index}>
                    <TdFixed>
                      {tableNumber({
                        pageCurrent: filter.page,
                        dataLength: filter.per_page,
                        index,
                      })}
                    </TdFixed>
                    <TdFixed>
                      <ButtonGroup>
                        <ButtonDetail
                          icon
                          noText
                          onClick={() =>
                            setModalConfig({
                              ...modalConfig,
                              type: 'detail',
                              id: e.id_seleksi_vendor_aset,
                            })
                          }
                        />
                        {e?.status_approval === 'REV' && (
                          <ButtonUpdate
                            icon
                            noText
                            variant="success"
                            onClick={() =>
                              setModalConfig({
                                ...modalConfig,
                                type: 'update',
                                id: e.id_seleksi_vendor_aset,
                              })
                            }
                          />
                        )}
                      </ButtonGroup>
                    </TdFixed>
                    <Td>
                      <div>
                        {
                          DateConvert(new Date(e?.tgl_seleksi_vendor_aset))
                            .custom
                        }
                      </div>
                      <div>{e.no_seleksi_vendor_aset ?? '-'}</div>
                    </Td>
                    <Td>
                      <div>
                        {DateConvert(new Date(e?.tgl_permintaan_aset)).custom}
                      </div>
                      <div>{e.no_permintaan_aset ?? '-'}</div>
                    </Td>
                    <Td>{e.nama_vendor ?? '-'}</Td>
                    <Td>{e?.nama_aset ?? '-'}</Td>
                    <Td className="text-right">
                      {
                        RupiahConvert(String(e?.harga_kesepakatan ?? 0))
                          .getWithComa
                      }
                    </Td>
                    <Td>
                      <ApprovalStatusButton
                        variant={
                          getStatusApprovalLabel(e?.status_approval).variant
                        }
                      >
                        {getStatusApprovalLabel(e?.status_approval).label}
                      </ApprovalStatusButton>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={11}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </Tr>
              )}
            </TBody>
          </Table>
          <Pagination
            dataLength={filter?.per_page}
            dataNumber={filter?.page * filter?.per_page - filter?.per_page + 1}
            dataPage={
              filter?.dataCount < filter?.per_page
                ? filter?.dataCount
                : filter?.page * filter?.per_page
            }
            dataCount={getSeleksiVendorAset?.data?.data_count}
            currentPage={filter?.page}
            totalPage={getSeleksiVendorAset?.data?.total_page}
            onPaginationChange={onPaginationChange}
            onDataLengthChange={onPaginationDataLengthChange}
          />
        </>
      )}

      {modalFilter && (
        <Formik
          enableReinitialize
          initialValues={formInitialValuesFilter}
          onSubmit={formSubmitHandlerFilter}
        >
          <ModalFilter toggle={onFilterButtonClickkHandler} />
        </Formik>
      )}

      {modalConfig.show && (
        <Formik
          initialValues={modalConfig?.data}
          validationSchema={formValidationSchema}
          onSubmit={modalSubmitHandler}
        >
          <ModalForm
            modalConfig={modalConfig}
            setModalConfig={setModalConfig}
          />
        </Formik>
      )}
    </CRUDLayout>
  );
};
